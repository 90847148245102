<template>
   <v-btn
        prepend-icon="mdi-plus"      
        text="Add new product"
         @click="dialogVisible = true"
      ></v-btn>
  <v-dialog v-model="dialogVisible" max-width="900">

    <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        bottom
        color="deep-purple-accent-4"
      ></v-progress-linear>
   
 
      <v-card class="py-4">
        <v-card-text align="center" justify="center"
          >Formulário de adição de pratos</v-card-text
        >
        <v-btn
          style="position: absolute; right: 0"
          class="float-right"
          icon
          variant="text"
           @click="dialogVisible = false"      
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <v-card-text>
          <div>
          
                <div class="image-container">
                  <div
                    v-for="(image, index) in orderedImages"
                    :key="index"
                    class="image-square"
                  >
                    <img :src="image.url" alt="Uploaded Image" />
                    <v-btn
                      density="compact"
                      size="small"
                      icon="mdi-close"
                      @click="removeImage(index)"
                      class="remove-icon"
                    ></v-btn>
                  </div>
                </div>

                <!-- File input for uploading images -->
                <div class="input-container">
                  <v-card
                    class="py-4 rounded"
                    align="center"
                    justify="center"
                    color="#CFD8DC"
                    elevation="0"
                  >
                    <div align="center" justify="center">
                      <v-btn variant="tex">Clique para Carregar imagem</v-btn>
                    </div>
                  </v-card>
                  <input
                    type="file"
                    ref="fileInput"
                    @change="handleFileChange"
                    multiple
                    class="mb-4 hide-input"
                  />
                </div>
       
          
                <v-text-field
                  v-model="productName"
                  type="text"
                  placeholder="Nome do Prato"
                  variant="outlined"
                  density="comfortable"
                ></v-text-field>
                <v-text-field
                  v-model="productPrice"
                  type="number"
                  placeholder="Price "
                  variant="outlined"
                  density="comfortable"
                ></v-text-field>

                <v-text-field
                  v-model="productAvailableQuantity"
                  type="number"
                  placeholder="Quantidade pessoas"
                  variant="outlined"
                  density="comfortable"
                ></v-text-field>
                <p class="text-subtitle pb-5">Mais detalhes do Buffet</p>
                  <!-- Quill editor -->
                  <div id="editor"></div>           
             
             
           
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-scale-transition>
            <div v-if="!loading" class="text-center"></div>
          </v-scale-transition>
          <v-btn @click="uploadImages">Salvar producto</v-btn>
        </v-card-actions>
      </v-card>
      <div v-if="showSuccessMessage" class="dialog">
        <v-card class="success-message py-8">
          <div align="center" justify="center">
            <v-btn color="#4CAF50" variant="outlined" icon="mdi-check"></v-btn>
          </div>

          <v-card-text align="center" justify="center">
            Produto adicionado com sucesso!
          </v-card-text>
        </v-card>
      </div>
   
  </v-dialog>
</template>

<script>
import Quill from 'quill/core';
import 'quill/dist/quill.snow.css';

// Import required Quill modules
import Toolbar from 'quill/modules/toolbar';
import Snow from 'quill/themes/snow';
import Bold from 'quill/formats/bold';
import Italic from 'quill/formats/italic';
import Underline from 'quill/formats/underline';
import List from 'quill/formats/list';
import Link from 'quill/formats/link';
import Header from 'quill/formats/header';
import image from 'quill/formats/image';

Quill.register({
  'modules/toolbar': Toolbar,
  'themes/snow': Snow,
  'formats/bold': Bold,
  'formats/header': Header,
  'formats/italic': Italic,
  'formats/underline': Underline,
  'formats/list': List,
  'formats/link': Link,
  'formats/image': image,
});
export default {
  data() {
    return {
      quill: null,
      dialogVisible:false,
      uploadedImages: [],
      loading: false,
      showSuccessMessage: false,
      orderedImages: [],
      selectedFiles: null,
      productName: "",
      productDescription: "",
      productPrice: "",
      productAvailableQuantity: "", // Changed to a string for consistency
    };
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.$nextTick(() => {
          if (!this.quill) {
            this.initializeQuill();
          }
        });
      }
    },
  },
  methods: {
    initializeQuill() {
      this.quill = new Quill('#editor', {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'link'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['image','code-block'],
          ]
        }
      });

      // Ensure Quill is fully initialized
      setTimeout(() => {
        this.quill.on('text-change', () => {
          // Optional: Handle text change events if needed
        });
      }, 100);
    },
    // Handle file selection
    handleFileChange(event) {
      const files = event.target.files;

      this.selectedFiles = this.selectedFiles || [];

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.uploadedImages.push({
            url: e.target.result,
            file: files[i],
          });
          this.orderImages();
        };
        reader.readAsDataURL(files[i]);

        this.selectedFiles.push(files[i]);
      }
    },
    // Order uploaded images
    orderImages() {
      this.orderedImages = [...this.uploadedImages];
    },
    // Remove uploaded image
    removeImage(index) {
      this.uploadedImages.splice(index, 1);
      this.orderImages();
    },
    // Upload product data and images
    uploadImages() {
      if (!this.quill) {
        console.error('Quill editor is not initialized');
        return;
      }
      // Verifica se pelo menos uma imagem foi carregada
      if (this.selectedFiles && this.selectedFiles.length > 0) {
        console.log("Sending to database:", this.orderedImages);
        this.loading = true; // Mostra a barra de progresso
        // Reset uploadedImages and orderedImages after sending to the database
        this.uploadedImages = [];
        this.orderedImages = [];
        const delta = this.quill.getContents();

        const formData = new FormData();
        formData.append("productName", this.productName);
        formData.append("productDescription", this.productDescription);
        formData.append("productPrice", parseFloat(this.productPrice));
        formData.append(
          "productAvailableQuantity",
          parseInt(this.productAvailableQuantity)
        );
        formData.append('delta', JSON.stringify(delta));

        // Append all files to the same "images" field
        for (let i = 0; i < this.selectedFiles.length; i++) {
          formData.append("images", this.selectedFiles[i]);
        }

        // Send a single request with all data
        fetch("https://app.brendkit.com/CaterUpload", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then(() => {
            this.loading = false; // Hide progress bar
            this.showSuccessMessage = true; // Show success message
            setTimeout(() => {
              this.showSuccessMessage = false; // Hide success message after a delay
              this.resetForm(); // Reset the form after hiding success message
            }, 3000); // Adjust the delay as needed
          })
          .catch((error) => {
            console.error("Error uploading products:", error);
            this.loading = false; // Hide progress bar in case of an error
          });
      } else {
        // Se nenhuma imagem foi carregada, exibe uma mensagem de erro ou notificação
        console.error("Error: No images uploaded");
        // Aqui você pode exibir uma mensagem para o usuário, por exemplo:
        alert(
          "Por favor, carregue pelo menos uma imagem antes de salvar o produto."
        );
      }
    },

    // Reset form fields
    resetForm() {
      this.uploadedImages = [];
      this.orderedImages = [];
      this.selectedFiles = null;
      this.productName = "";
      this.productDescription = "";
      this.productPrice = "";
      this.productAvailableQuantity = "";
    },
    // Open file input dialog
    chooseFile() {
      this.$refs.fileInput.click();
    },
  },
};
</script>
<style scoped>
#editor{
  height: 300px;
  border-radius: 0 0 8px 8px;
}
.success-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image-container {
  display: flex;
  flex-wrap: wrap;
}

.image-square {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 10px;
  overflow: hidden;
}

.image-square img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-icon {
  position: absolute;
  top: 5px;
  right: 5px;

  color: white;
  cursor: pointer;

  background-color: rgba(0, 0, 0, 0.7);
}
.input-container {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 8px;

  cursor: pointer;
}
.hide-input {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
}
.dialog {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.2);
}
</style>
