<template>
  <v-dialog v-model="dialogVisibleForm" max-width="800" >
    <v-card class="py-5">
      <EventForm />
    </v-card>
    
  </v-dialog>
 
  <div class="furniture-list">
    <v-container>
      <v-sheet>
          <v-row>
    <v-col cols="3">
      <v-text-field append-inner-icon="mdi-magnify" density="compact" variant="outlined" v-model="searchQuery" label="Search products..." outlined @input="performSearch" clearable></v-text-field>
    </v-col>
    <v-col class="d-flex justify-end">
                <v-btn  prepend-icon="mdi-plus"
                    text="Add new product"
                        @click="dialogVisibleForm = true"
                    ></v-btn>
                </v-col>
  </v-row>
      </v-sheet>                 <!-- Search Bar -->
 
  <v-sheet color="#f5f5f5" >
      <v-row>

<v-col v-for="item in displayedItems" :key="item._id" cols="12" md="6" lg="4">
  <v-card style="position: relative;" class="pa-2 position-relative" height="480">
      <div style="display: flex; flex-direction: row; justify-content: space-between;" class="py-0 my-0" >
    <div class=" me-auto">  
          <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn size="small" variant="text" icon="mdi-dots-vertical" v-bind="props"></v-btn>
          </template>

          <v-list>
            <v-list-item             
            >
              <v-list-item-title>
                  <v-btn variant="icon" color="primary" @click="openEditDialog(item)">Edit</v-btn>
                  <v-btn @click="openDiscountDialog(item)">Apply Discount</v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
       
       </div>
  
    <div class="">    
      <v-switch
                  v-model="item.featured"
                  @change="() => toggleFeatured(item)"                  
                  :color="item.featured ? 'green' : ''"
                  hide-details
                  size="x-small"
                ></v-switch></div>
  </div>
   
 
    <!-- Skeleton loader -->
    <template v-if="loading">     
      <v-skeleton-loader type="card, actions"></v-skeleton-loader>
    </template>

    <!-- Actual content -->
    <template v-else>
      <v-row v-if="!loading">
        
        <v-col v-for="(cover, index) in item.coverImage" :key="index" cols="12">
          <v-img :src="cover.path" height="200" cover></v-img>
        </v-col>
      </v-row>
      <div class=" d-flex pt-2 justify-start ga-2" v-if="showChips(item)">
<v-chip v-if="hasDiscount(item)" label size="small" color="secondary">
  Discount
</v-chip>
<v-chip v-if="isArchived(item)" label size="small" color="red">
  Archived
</v-chip>
<v-chip v-if="isFeatured(item)" label size="small" color="green">
  Featured
</v-chip>
</div>


      <v-card-text class="d-flex justify-space-between">
        <div style="max-width: 70%;">
          <h4 class="text-subtitle-2 font-weight-medium mb-2">{{ item.productName }}</h4>
          <p>{{ item.productGuest }}</p>
          <p>{{ item.evenTime }}</p>
        </div>

        <v-chip label>{{ `$${item.price.toFixed(2)}` }}</v-chip>
      </v-card-text>
      
      <v-card-actions class="card-actions-bottom-2">
        <v-spacer></v-spacer>
        
        <v-btn variant="outlined" color="indigo-accent-4" text="View Details" @click="openDetailsDialog(item)"></v-btn>
       
      </v-card-actions>
      
    </template>
  </v-card>

</v-col>
<!-- Discount Dialog -->
<v-dialog v-model="discountDialogVisible">
  <v-card class="mx-auto" max-width="450">
    <v-card-title>Apply Discount</v-card-title>
    <v-card-text>
      <v-text-field v-model="discountPercentage" label="Discount (%)" type="number"></v-text-field>      
      <v-row>
                  <v-col cols="6">
                    <v-text-field  v-model="startDate" type="date" label="Start Date" variant="outlined" density="comfortable"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="endDate" type="date" label="End Date" variant="outlined" density="comfortable"></v-text-field>
                  </v-col>
                </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="blue darken-1" @click="removeDiscount" v-if="selectedItem && selectedItem.discountPercentage !== null">Remove</v-btn>
      <v-btn color="blue darken-1" @click="discountDialogVisible = false">Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" @click="applyDiscount">Apply</v-btn>
      <v-btn color="blue darken-1" @click="editDiscount" v-if="selectedItem && selectedItem.discountPercentage !== null">Update</v-btn>

    </v-card-actions>
  </v-card>
</v-dialog>

</v-row>
  </v-sheet>

  <!-- Toggle See all button -->

  <!-- <v-btn class="me-8" variant="text" @click="toggleSeeAll">
<span class="text-decoration-underline text-none">{{ showAllItems ? 'Hide All' : 'See All' }}</span>
</v-btn> -->
  

  <div class="d-flex align-center justify-center pa-4">
    <v-btn
      :disabled="currentPage === 1"
      density="comfortable"
      icon="mdi-arrow-left"
      variant="tonal"
      rounded
      @click="prevPage"
    ></v-btn>

    <div class="mx-2 text-caption">
      Page {{ currentPage }} of {{ pagesCount }}
    </div>

    <v-btn
      :disabled="currentPage >= pagesCount"
      density="comfortable"
      icon="mdi-arrow-right"
      variant="tonal"
      rounded
      @click="nextPage"
    ></v-btn>
  </div>

    </v-container>
   <!-- View Details Dialog -->
   <v-dialog v-model="dialogVisible" max-width="900px">  
    <v-row no-gutters>
        <v-col>
            <v-carousel cycle height="550">
          <v-carousel-item v-for="(image, index) in selectedItem.images" :key="index">
            <v-img :src="image.path" height="550" cover>
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
              <template v-slot:default>
                <v-container class="fill-height pa-0">
                  <v-row class="fill-height ma-0">
                    <v-col class="d-flex flex-column justify-end align-center">
                      <v-card class="transparent" flat>
                        <v-card-text class="text-center white--text" v-if="image.caption">
                          {{ image.caption }}
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-img>
          </v-carousel-item>
        </v-carousel>
        </v-col>
        <v-col>
            <v-card flat class="overflow-auto px-2 " height="550">
       
        
       <v-card-text class="pb-10">
   <div class="d-flex justify-space-between">
           <h2 class="text-h5">{{ selectedItem.productName }}</h2>
                             
           
           </div>
           <v-divider class=""></v-divider>
           <v-row class="d-flex justify-space-between">
            <v-col class="pt-5" cols="6">Convidad@s</v-col>
            <v-col cols="6" class="d-flex justify-end">
              <p class="ma-2" label>
               {{ selectedItem.productGuest}} 
          </p>
            </v-col>
           </v-row>
           <v-divider class=""></v-divider>
           <v-row class="d-flex justify-space-between">
            <v-col class="pt-5" cols="6">Tipo de evento</v-col>
            <v-col cols="6" class="d-flex justify-end">
              <p class="ma-2" label>
               {{ selectedItem.selectedSubtipo }} 
          </p>
            </v-col>
           </v-row>       
           <v-divider class=""></v-divider>
           <v-row class="d-flex justify-space-between">
            <v-col class="pt-5" cols="6">Categoria do evento</v-col>
            <v-col cols="6" class="d-flex justify-end">
              <p class="ma-2" label>
               {{ selectedItem.selectedSubcategories }} 
          </p>
            </v-col>
           </v-row>       
           <v-divider class=""></v-divider>   
           <v-row class="d-flex justify-space-between">
            <v-col class="pt-5" cols="6">Data</v-col>
            <v-col cols="6" class="d-flex justify-end">
              <p class="ma-2" label>
               {{ selectedItem.productExpirationDate }} 
          </p>
            </v-col>
           </v-row>
           <v-divider class=""></v-divider>
           <v-row class="d-flex justify-space-between">
            <v-col class="pt-5" cols="6">Horário</v-col>
            <v-col cols="6" class="d-flex justify-end">
              <p class="ma-2" label>
               {{ selectedItem.evenTime }} 
          </p>
            </v-col>
           </v-row>
          <v-divider class=""></v-divider>
           <v-row class="d-flex justify-space-between">
            <v-col class="pt-5" cols="6">Preço</v-col>
            <v-col cols="6"  class="d-flex justify-end mt-2"> <v-chip label>{{ `$${selectedItem.price.toFixed(2)}` }}</v-chip></v-col>
           </v-row>
        
      
            <!-- Render Quill delta content -->
             <v-divider class="my-4"></v-divider>
             <p class="text-h6">Descrição detalhada</p>           
             <div class="quill-class" v-html="renderContent(selectedItem.content)"></div>
            </v-card-text>
       
     
       <v-card-actions class="card-actions-bottom">
           <v-btn color="red" @click="deleteFurniture(selectedItem._id)">Delete</v-btn>
           <v-btn
        :color="selectedItem.archived ? 'green' : 'secondary'"
        @click="toggleArchiveFurniture(selectedItem)"
      >
        {{ selectedItem.archived ? 'Unarchive' : 'Archive' }}
      </v-btn>

         <v-spacer></v-spacer>
        
       </v-card-actions>
     </v-card>
     <v-btn size="small" style="position: absolute; right: -7%; top: -7%; background-color: rgb(255, 255,255, 0.1);" flat icon text @click="dialogVisible = false" ><v-icon color="#000">mdi-close</v-icon></v-btn>
        </v-col>
       
     
    </v-row>
        <!-- Pagination -->

   
  </v-dialog>

<!-- Edit Dialog -->
<v-dialog v-model="isEditDialogOpen" max-width="600px">
  <v-card>
    <v-card-title>Edit evento</v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field v-model="editItem.productName" label="Product Name" required></v-text-field> 
        <v-textarea :counter="180" rows="3" persistent-counter v-model="editItem.productDescription" label="Breve Descrição" type="text" required></v-textarea>
      <v-text-field v-model="editItem.productLocal" label="Local do evento" type="text" required></v-text-field>
      <SelectTypeComponent v-model="editItem.selectedSubtipo" />
      <SelectCategoryComponent v-model="editItem.selectedSubcategories" />
      <v-text-field v-model="editItem.productGuest" label="Convidado" type="text" required></v-text-field>
      <v-text-field v-model="editItem.productAddress" label="Endereço" type="text" required></v-text-field>
      <v-text-field v-model="editItem.productStatus" label="Status" type="text" required></v-text-field>
      <v-text-field v-model="editItem.productExpirationDate" label="Data" type="date" required></v-text-field>
      <v-text-field v-model="editItem.evenTime" label="Hora" type="time" required></v-text-field>        
        <v-text-field v-model="editItem.price" label="Price" type="number" required></v-text-field>

        <!-- Edit Cover Images -->
        <v-row v-if="editItem.coverImage && editItem.coverImage.length > 0">
          <v-col v-for="(cover, index) in editItem.coverImage" :key="index" cols="12">
            <v-img :src="cover.path" height="200" cover></v-img>
            <input type="file" @change="handleCoverImageUpload($event, index)">
         
          </v-col>
        </v-row>

        <!-- Edit Images with Captions -->
        <v-row v-if="editItem.images && editItem.images.length > 0">
          <v-col v-for="(image, index) in editItem.images" :key="index" cols="12">
            <v-img :src="image.path" height="200" cover></v-img>
            <input class="my-3" type="file" @change="handleImageUpload($event, index)">
            <v-text-field flat variant="solo" density="compact" v-model="image.caption" label="Add image caption here"></v-text-field>
       
          </v-col>
        </v-row>

        <!-- Button to Add More Images -->
        <v-btn @click="addImageField">Add More Images</v-btn>

        <!-- Quill Editor for Content -->
        <quill-editor style="height: 240px;" v-model="editItem.content" :options="editorOptions" @ready="onEditorReady"></quill-editor>
      </v-form>
    </v-card-text>
    <v-card-actions>       
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="isEditDialogOpen = false">Cancel</v-btn>
      <v-btn color="blue darken-1" text @click="saveEdits">Save</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>


  </div>
</template>


<script>
import { ref, computed, onMounted,onBeforeUnmount, nextTick } from 'vue';
import axios from 'axios';
import Delta from 'quill-delta';
import Quill from 'quill';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import EventForm from '../components/EventosUpload.vue';
import SelectTypeComponent from './SelectTypeComponent.vue';
import SelectCategoryComponent from './SelectCategoryComponent.vue';


export default {
  name: 'FurnitureList',
  components: {
    QuillEditor,
    EventForm,
    SelectTypeComponent,
    SelectCategoryComponent,
  },
  props: {
    item: Object, // Assuming 'item' is passed as a prop containing product details
  },
  setup() {
    const furnitureItems = ref([]);   
    const isEditDialogOpen = ref(false);
    const dialogVisibleForm = ref(false);
    const discountDialogVisible = ref(false);
    const discountPercentage = ref(0);
    const startDate = ref(null);
    const endDate = ref(null);
    const productExpirationDate =ref (null);
    const discountStartDate = ref(null); // Define reactive ref for discountStartDate
    const discountEndDate = ref(null); // Define reactive ref for discountEndDate
    const dialogVisible = ref(false);
    const selectedItem = ref({ images: [], content: '',  archived: false });
    const editItem = ref({
      _id: '',
      productName: '',      
      price: 0,
      productDescription:'',       
      productLocal:'',
      selectedSubtipo:'',
      selectedSubcategories:'',
      productGuest:'',
      productAddress:'',
      productStatus:'',
      productExpirationDate:'',
      evenTime:'',  
      content: '', // Initialize content
      coverImage: [],
      images: [],
    });
    const loading = ref(true); // Loading state

    let quillInstance = null;
    const searchQuery = ref('');
  const currentPage = ref(1);
  const page = ref(1); // Current page
  const itemsPerPage = 6; // Adjust as needed
  const showAllItems = ref(false); // Flag to toggle showing all items
  const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

 
// Computed properties for chips visibility
const hasDiscount = computed(() => (item) => {
return item.discountPercentage !== null && item.discountPercentage > 0;
});

const isArchived = computed(() => (item) => {
return item.archived === true;
});

const isFeatured = computed(() => (item) => {
return item.featured === true;
});

// Compute whether to show chips based on any of the conditions being true
const showChips = computed(() => (item) => {
return hasDiscount.value(item) || isArchived.value(item) || isFeatured.value(item);
});


 
  const toggleArchiveFurniture = async (item) => {
  try {
    const endpoint = item.archived
      ? `http://localhost:3000/api/events/${item._id}/unarchive`
      : `http://localhost:3000/api/events/${item._id}/archive`;
    const response = await axios.patch(endpoint);
    selectedItem.value = response.data; // Update selectedItem with the response data
    console.log('Furniture archive status toggled successfully');
  } catch (error) {
    console.error('Error toggling furniture archive status:', error);
  }
};
 
const fetchFurniture = async () => {
  try {
    const response = await axios.get('http://localhost:3000/api/events');
    furnitureItems.value = response.data.map(item => ({
      ...item,
      // Ensure default values or handle null cases
      discountPercentage: item.discountPercentage !== null ? item.discountPercentage : 0,
      archived: item.archived || false, // Ensure default value or handle null cases
      featured: item.featured || false, // Example default value
      discountStartDate: item.discountStartDate ? new Date(item.discountStartDate) : null,
      discountEndDate: item.discountEndDate ? new Date(item.discountEndDate) : null,
    }));
    loading.value = false;
    console.log('Fetched furniture items:', furnitureItems.value);

    // Optionally log each item to verify data
    furnitureItems.value.forEach(item => {
      console.log('Item:', item._id, 'Discount:', item.discountPercentage, 'Archived:', item.archived, 'Featured:', item.featured);
    });
  } catch (error) {
    console.error('Error fetching furniture items:', error);
  }
};



  const toggleFeatured = async (item) => {
    try {
      console.log('Toggling featured status for item:', item._id, 'Current status:', item.featured);
      const response = await axios.patch(`http://localhost:3000/api/events/${item._id}/featured`, {
        featured: item.featured,
      });
      item.featured = response.data.featured;
      console.log('Featured status updated to:', item.featured);
    } catch (error) {
      console.error('Error toggling featured status:', error);
    }
  };


     
      setTimeout(() => {
      loading.value = false;
    }, 1000); // Simulated loading delay of 1 second
  
  

    onMounted(() => {
      const intervalId = setInterval(fetchFurniture, 3000); // Call fetchFurniture every 5 seconds

      // Clean up the interval on component unmount (equivalent to componentWillUnmount)
      onBeforeUnmount(() => {
        clearInterval(intervalId);
      });
    });

    const viewDetails = (item) => {
      console.log('View details:', item);
    };
   // Perform search based on searchQuery
   const performSearch = () => {
   
    const query = searchQuery.value.toLowerCase().trim();
    if (query === '') {
      fetchFurniture(); // Reset to all items if query is empty
    } else {
      furnitureItems.value = furnitureItems.value.filter(item =>
        item.productName.toLowerCase().includes(query)
      );
    }
  };
 // Computed property for pagination
 const displayedItems = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage;
    return furnitureItems.value.slice(startIndex, startIndex + itemsPerPage);
  });

  // Computed property for total pages
  const pagesCount = computed(() => Math.ceil(furnitureItems.value.length / itemsPerPage));
   // Method to navigate to previous page
   const prevPage = () => {
    if (currentPage.value > 1) {
      currentPage.value--;
    }
  };

  // Method to navigate to next page
  const nextPage = () => {
    if (currentPage.value < pagesCount.value) {
      currentPage.value++;
    }
  };
   // Method to toggle showing all items or paginated view
   const toggleSeeAll = () => {
    showAllItems.value = !showAllItems.value;
    if (showAllItems.value) {
      // Set itemsPerPage to display all items
      itemsPerPage.valueOf = furnitureItems.value.length;
    } else {       
      page.value = 1;
    }
  };

   // Function to convert Quill Delta to HTML
   const convertDeltaToHtml = (delta) => {
  const quill = new Quill(document.createElement('div'));
  quill.setContents(delta);
  return quill.root.innerHTML;
};

const renderContent = (delta) => {
  if (!delta) return '';
  return convertDeltaToHtml(new Delta(JSON.parse(delta)));
};


  
    const openEditDialog = async (item) => {
      console.log('Opening edit dialog with item:', item);
      editItem.value = { ...item };
      isEditDialogOpen.value = true;

      await nextTick();

      if (quillInstance) {
        quillInstance.setContents(JSON.parse(editItem.value.content));
      } else {
        console.error('Quill instance is not available.');
      }
    };
    const openDetailsDialog = async (item) => {
  try {
    const response = await axios.get(`http://localhost:3000/api/events/${item._id}`);
    selectedItem.value = response.data; // Update selectedItem with fetched data
    dialogVisible.value = true; // Show the dialog
  } catch (error) {
    console.error('Error fetching furniture details:', error);
  }
};

    const onEditorReady = (editorInstance) => {
      quillInstance = editorInstance;
    };

    // Handle Cover Image Upload
    const handleCoverImageUpload = (event, index) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          editItem.value.coverImage[index].path = reader.result;
        };
        reader.readAsDataURL(file);
        editItem.value.coverImage[index].file = file;
      }
    };

    const handleImageUpload = (event, index) => {
const file = event.target.files[0];
if (file) {
  const reader = new FileReader();
  reader.onload = () => {
    editItem.value.images[index].path = reader.result;
  };
  reader.readAsDataURL(file);
  editItem.value.images[index].file = file;
}
};


    const addImageField = () => {
editItem.value.images.push({ path: '', caption: '', file: null });
};



const saveEdits = async () => {
try {
  if (quillInstance) {
      const formattedproductExpirationDate = productExpirationDate.value ? new Date(productExpirationDate.value) : null;
    const deltaContent = quillInstance.getContents();
    const formData = new FormData();
    formData.append('productName', editItem.value.productName);     
    formData.append('price', editItem.value.price);
    formData.append('productDescription', editItem.value.productDescription);
    formData.append('productLocal', editItem.value.productLocal);
    formData.append('selectedSubtipo', editItem.value.selectedSubtipo);
    formData.append('selectedSubcategories', editItem.value.selectedSubcategories);
    formData.append('productGuest', editItem.value.productGuest);
    formData.append('productAddress', editItem.value.productAddress);
    formData.append('productStatus', editItem.value.productStatus);
    formData.append('productExpirationDate', editItem.value.productExpirationDate);
    formData.append('evenTime', editItem.value.evenTime);

    formData.append('content', JSON.stringify(deltaContent));

    // Append cover image file if exists
    if (editItem.value.coverImage.length > 0 && editItem.value.coverImage[0].file) {
      formData.append('coverImage', editItem.value.coverImage[0].file);
    }

    // Append new image files and captions
    editItem.value.images.forEach((image, index) => {
      if (image.file) {
        formData.append('images', image.file);
        formData.append(`captions[${index}]`, image.caption); // Ensure captions are appended with correct index
      }
    });

    const response = await axios.put(`http://localhost:3000/api/events/${editItem.value._id}`, formData, {
      productExpirationDate: formattedproductExpirationDate,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    console.log('Furniture updated successfully:', response.data);
    isEditDialogOpen.value = false;
    fetchFurniture(); // Refresh the list after editing
  } else {
    console.error('Quill instance is not available.');
  }
} catch (error) {
  console.error('Error updating furniture:', error);
}
};


    // Method to delete furniture
    const deleteFurniture = async (id) => {
      try {
        await axios.delete(`http://localhost:3000/api/events/${id}`);
        console.log('Furniture deleted successfully');
        fetchFurniture(); // Refresh the list after deletion
      } catch (error) {
        console.error('Error deleting furniture:', error);
      }
    };

    const openDiscountDialog = (item) => {
  selectedItem.value = item;
  discountPercentage.value = item.discountPercentage || 0;
  discountStartDate.value = item.discountStartDate ? new Date(item.discountStartDate) : null;
  discountEndDate.value = item.discountEndDate ? new Date(item.discountEndDate) : null;
  startDate.value = discountStartDate.value ? formatDate(discountStartDate.value) : null;
  endDate.value = discountEndDate.value ? formatDate(discountEndDate.value) : null;
  discountDialogVisible.value = true;
};





    // Apply discount
    const applyDiscount = async () => {
    try {
      const discountedPrice = calculateDiscountedPrice(selectedItem.value.price, discountPercentage.value);
      
      const response = await axios.patch(`http://localhost:3000/api/events/${selectedItem.value._id}/discount`, {
        discountPercentage: discountPercentage.value,
        discountedPrice,
        discountStartDate: startDate.value, // Define discountStartDate
        discountEndDate: endDate.value,     // Define discountEndDate
      });

      // Optionally update local state
      selectedItem.value.discountPercentage = response.data.discountPercentage;
      selectedItem.value.discountedPrice = response.data.discountedPrice;
      selectedItem.value.discountStartDate = response.data.discountStartDate;
      selectedItem.value.discountEndDate = response.data.discountEndDate;

      closeDiscountDialog(); // Close the discount dialog
    } catch (error) {
      console.error('Error applying discount:', error);
    }
  };


  const editDiscount = async () => {
  try {
    const discountedPrice = calculateDiscountedPrice(selectedItem.value.price, discountPercentage.value);

    // Format startDate and endDate for API request
    const formattedStartDate = startDate.value ? new Date(startDate.value) : null;
    const formattedEndDate = endDate.value ? new Date(endDate.value) : null;

    const response = await axios.put(`http://localhost:3000/api/events/${selectedItem.value._id}/discount`, {
      discountPercentage: discountPercentage.value,
      discountedPrice,
      discountStartDate: formattedStartDate,
      discountEndDate: formattedEndDate,
    });

    // Optionally update local state with response data
    selectedItem.value.discountPercentage = response.data.discountPercentage;
    selectedItem.value.discountedPrice = response.data.discountedPrice;
    selectedItem.value.discountStartDate = response.data.discountStartDate;
    selectedItem.value.discountEndDate = response.data.discountEndDate;

    closeDiscountDialog(); // Close the discount dialog
  } catch (error) {
    console.error('Error editing discount:', error);
  }
};


  const removeDiscount = async () => {
try {
  const response = await axios.delete(`http://localhost:3000/api/events/${selectedItem.value._id}/discount`);

  if (response.status === 200) {
    selectedItem.value.discountPercentage = null;
    selectedItem.value.discountedPrice = null;
    selectedItem.value.discountStartDate = null;
    selectedItem.value.discountEndDate = null;

    closeDiscountDialog();
    console.log('Discount removed successfully');
  } else {
    console.error('Failed to remove discount:', response.statusText);
  }
} catch (error) {
  console.error('Error removing discount:', error);
}
};


  const closeDiscountDialog = () => {
    discountDialogVisible.value = false;
    discountPercentage.value = 0;
    startDate.value = discountStartDate.value;
    endDate.value = discountEndDate.value;
  };


  const calculateDiscountedPrice = (originalPrice, discountPercentage) => {
    return originalPrice * (1 - discountPercentage / 100);
  };



 

    return {
      furnitureItems,     
      isEditDialogOpen,
      dialogVisible,
      openDetailsDialog,
      editItem,
      page,
      loading,
      selectedItem,
      searchQuery,     
      toggleArchiveFurniture,   
      discountDialogVisible,
      discountPercentage,
     startDate,
     endDate,
     discountStartDate,
     discountEndDate,
      toggleFeatured,
      openDiscountDialog,
      applyDiscount,
      editDiscount,
    removeDiscount,
    closeDiscountDialog,
    currentPage,
    itemsPerPage,
    productExpirationDate,
      viewDetails,
      dialogVisibleForm,
      handleCoverImageUpload,
      handleImageUpload,
      renderContent,
      addImageField,       
      convertDeltaToHtml,
      openEditDialog,
      saveEdits,
      onEditorReady,
      deleteFurniture,
      
        // Explicitly referencing to suppress ESLint errors
    // performSearch, displayedItems, and pagesCount are used in template bindings
    // so ESLint might not detect their usage correctly
     performSearch, // Uncomment these lines if necessary
     displayedItems,
     pagesCount,
     prevPage,
     nextPage,      
     toggleSeeAll,
     showAllItems,
     hasDiscount,
    isArchived,
    isFeatured,
    showChips,
    };
  },
};
</script>




<style scoped>
.card-actions-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: fixed;
  bottom: 0px;
  background-color: white;
  left: auto;
  right: 0;
  padding: 8px; /* Adjust padding as needed */
}
.card-actions-bottom-2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  bottom: 10px;
  background-color: white;
  left: auto;
  right: 10px;
  padding: px; /* Adjust padding as needed */
}
.v-chip{
  margin: 0 !important;
}
.quill-class ul{
  margin-left: 5% !important;
}


</style>
