<template>

    <h4 class="py-8"> Crie Marcas</h4>
    <v-form @submit.prevent="addBrand">
      <v-row>
        <v-col cols="12">
          <v-text-field variant="outlined" density="compact" v-model="newBrand.name" label="Nome de grupo de Marcas" required></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-row class="pl-5" v-for="(subbrand, subIndex) in newBrand.subbrands" :key="subIndex" >
            <v-col cols="10">
              <v-row>
                <v-col cols="8">
                  <v-text-field hide-details variant="outlined" density="compact" v-model="subbrand.name" label="adicione a marcas"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field hide-details type="number" variant="outlined" density="compact" v-model="subbrand.seats" label="seats"></v-text-field>
                </v-col>
              </v-row>
             
              
            </v-col>
            <v-col cols="2">
              <v-btn variant="text" size="small" @click="removeNewSubbrand(subIndex)" color="error" text icon>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
        
          </v-row>
        </v-col>
        <v-col class="pl-4" cols="6">
          <v-btn variant="outlined" size="small" @click="addNewSubbrand" color="primary" icon><v-icon>mdi-plus</v-icon>
            <v-tooltip                
            color="primary"
            activator="parent"
            location="end"
          >Adicionar Marca</v-tooltip>
          </v-btn>
     
        </v-col>
        <v-col cols="12" class="d-flex justify-end">              
          <v-btn class="my-4" type="submit" height="45" border="0" color="success">Salvar Marca</v-btn>
        </v-col>
      </v-row>
    </v-form>
<v-expansion-panels v-if="brands.length > 0">
<v-expansion-panel v-for="(brand, catIndex) in brands" :key="catIndex">
<v-expansion-panel-title v-model="brand.name">
 <h4>{{ brand.name }}</h4>
</v-expansion-panel-title>
<v-expansion-panel-text>
  <v-list>
      <v-list-item-group>
        <v-list-item>
          <v-list-item-content>
            <v-text-field variant="solo-filled" v-model="brand.name" label="Nome do grupo da marca" :disabled="!brand.editing"></v-text-field>
            <v-row class="pl-5" v-for="(subbrand, subIndex) in brand.subbrands" :key="subIndex" no-gutters>
              <v-col cols="10">
                <v-text-field variant="outlined" density="compact" v-model="subbrand.name" label="Nome da marca" :disabled="!brand.editing"></v-text-field>
                <v-text-field variant="outlined" density="compact" v-model="subbrand.seats" label="Acentos" :disabled="!brand.editing"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn v-if="brand.editing" variant="text" size="small" @click="deleteSubbrand(brand, subIndex)" color="error" text icon>
                  <v-icon>mdi-delete</v-icon>
                  <v-tooltip                
            color="primary"
            activator="parent"
            location="end"
          >Apagar marca</v-tooltip>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="brand.editing">
              <v-col cols="10">
                <v-text-field variant="outlined" density="compact" v-model="newSubbrand[brand._id]" label="Nova marca"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn size="small" @click="addSubbrand(brand)" color="primary" text icon>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn v-if="!brand.editing" @click="brand.editing = true" color="primary" class="mr-5"><v-icon>mdi-pencil</v-icon>Edit</v-btn>
            <v-btn v-else @click="saveBrand(brand)" color="success" class="mr-5"><v-icon>mdi-floppy</v-icon>Salvar marca</v-btn>
            <v-btn variant="outlined" @click="deleteBrand(brand)" color="error"><v-icon>mdi-delete</v-icon>Apagar</v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
      
    </v-list>
</v-expansion-panel-text>

    
</v-expansion-panel>

</v-expansion-panels>

<v-alert v-else>Sem marcas disponíveis.</v-alert>

 

</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';


const brands = ref([]);
const newBrand = ref({
name: '',
subbrands: [{ name: '', seats:'' }],
});

const newSubbrand = ref({});

const fetchBrands = async () => {
try {
const response = await axios.get('https://server.furnimoz.rent/api/brands');
brands.value = response.data.map(brand => ({ ...brand, editing: false }));
} catch (error) {
console.error('Error fetching brands:', error);
}
};



const addBrand = async () => {
try {
const response = await axios.post('https://server.furnimoz.rent/api/brands', newBrand.value);
brands.value.push({ ...response.data, editing: false });
newBrand.value = { name: '', subbrands: [{ name: '', seats:'' }] };
} catch (error) {
console.error('Error adding brand:', error);
}
};



const addNewSubbrand = () => {
newBrand.value.subbrands.push({ name: '', seats:'' });
};


const removeNewSubbrand = (subIndex) => {
newBrand.value.subbrands.splice(subIndex, 1);
};



const addSubbrand = async (brand) => {
if (!newSubbrand.value[brand._id]) return;
brand.subbrands.push({ name: newSubbrand.value[brand._id], seats: newSubbrand.value[brand._id] });
newSubbrand.value[brand._id] = '';
};


const saveBrand = async (brand) => {
try {
await axios.put(`https://server.furnimoz.rent/api/brands/${brand._id}`, brand);
brand.editing = false;
} catch (error) {
console.error('Error saving brand:', error);
}
};




const deleteBrand = async (brand) => {
try {
await axios.delete(`https://server.furnimoz.rent/api/brands/${brand._id}`);
console.log('Brand and subbrands deleted successfully');
fetchBrands();
} catch (error) {
console.error('Error deleting brand and subbrands:', error);
}
};



const deleteSubbrand = (brand, subIndex) => {
brand.subbrands.splice(subIndex, 1);
};



onMounted(fetchBrands);

</script>

<style scoped>
</style>
