<template>
  <v-data-table
    :headers="headers"
    :items="desserts"
    :sort-by="[{ key: 'name', order: 'asc' }]"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>My CRUD</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ props }">
            <v-btn class="mb-2" color="primary" dark v-bind="props">
              New Item
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="editedItem.clientname"
                      label="Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="editedItem.clienttype"
                      :items="['Person', 'Company']"
                      label="Client Type"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="editedItem.contact"
                      label="Contact"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="editedItem.carbrand"
                      :items="['First', 'Second', 'Third']"
                      label="Vehicle Brand"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="editedItem.email"
                      label="Email Address"
                      type="email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="editedItem.startdate"
                      label="Start Date"
                      type="date"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="editedItem.enddate"
                      label="End Date"
                      type="date"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="editedItem.description"
                      label="Description"
                      rows="3"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="close">
                Cancel
              </v-btn>
              <v-btn color="blue-darken-1" variant="text" @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

  <template v-slot:[`item.actions`]="{ item }">
      <v-icon class="me-2" size="small" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon size="small" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>

    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>
  </v-data-table>
</template>

<script>
import axios from 'axios';

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      { title: 'Name', key: 'clientname' },
      { title: 'Client Type', key: 'clienttype' },
      { title: 'Contact', key: 'contact' },
      { title: 'Vehicle Brand', key: 'carbrand' },
      { title: 'Email Address', key: 'email' },
      { title: 'Start Date', key: 'startdate' },
      { title: 'End Date', key: 'enddate' },
      { title: 'Description', key: 'description' },
      { title: 'Actions', key: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      clientname: '',
      clienttype: '',
      contact: '',
      carbrand: '',
      email: '',
      startdate: '',
      enddate: '',
      description: '',
    },
    defaultItem: {
      clientname: '',
      clienttype: '',
      contact: '',
      carbrand: '',
      email: '',
      startdate: '',
      enddate: '',
      description: '',
    },
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    },
  },

  watch: {
    dialog (val) {
      val || this.close();
    },
    dialogDelete (val) {
      val || this.closeDelete();
    },
  },

  created () {
    this.fetchRents();
  },

  methods: {
    async fetchRents () {
      try {
        const response = await axios.get('http://localhost:3000/api/rents');
        this.desserts = response.data;
      } catch (error) {
        console.error('Error fetching rents:', error);
      }
    },

    async editItem (item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    async deleteItem (item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },

    async deleteItemConfirm () {
      try {
        await axios.delete(`http://localhost:3000/api/rents/${this.editedItem._id}`);
        this.desserts.splice(this.editedIndex, 1);
        this.closeDelete();
      } catch (error) {
        console.error('Error deleting item:', error);
      }
    },

    async save () {
      try {
        if (this.editedIndex > -1) {
          // Update existing item
          await axios.put(`http://localhost:3000/api/rents/${this.editedItem._id}`, this.editedItem);
          Object.assign(this.desserts[this.editedIndex], this.editedItem);
        } else {
          // Create new item
          const response = await axios.post('http://localhost:3000/api/rents', this.editedItem);
          this.desserts.push(response.data);
        }
        this.close();
      } catch (error) {
        console.error('Error saving item:', error);
      }
    },

    close () {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    closeDelete () {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    initialize () {
      this.fetchRents();
    },
  },
};
</script>
