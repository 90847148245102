<template>
    <div class="iframe-container">
      <!-- Edit Button -->
      <v-btn @click="toggleEditMode" class="edit-button">
        {{ editMode ? 'Exit Edit Mode' : 'Enter Edit Mode' }}
      </v-btn>
      <!-- Refresh Button -->
      <v-btn @click="refreshPage" class="refresh-button">
        Refresh Page
      </v-btn>
    
    </div>
      <!-- Iframe with dynamic URL -->
      <iframe :src="iframeSrc" width="100%" height="100%" frameborder="0"></iframe>
  </template>
  
  <script>
  export default {
    data() {
      return {
        iframeSrc: 'https://www.motorize.me',
        editMode: false
      };
    },
    methods: {
      toggleEditMode() {
        this.editMode = !this.editMode;
        if (this.editMode) {
          // Add edit mode
          const url = new URL(this.iframeSrc);
          url.searchParams.set('edit', 'true');
          this.iframeSrc = url.toString();
        } else {
          // Remove edit mode
          this.iframeSrc = this.iframeSrc.replace('?edit=true', '');
        }
      },
      refreshPage() {
        // Simply refresh the iframe by setting its src to itself
        this.iframeSrc += '';
      }
    }
  };
  </script>
  
  
  <style scoped>
iframe{
    height: 100vh;
}
.iframe-container {
  display: flex;

  align-items: right;
  justify-content: end;
  position: relative;
}

.edit-button {
display: flex;
  right: 30px;

  z-index: 1000;
}
.refresh-button{
display: flex;
  right: 10px;
  z-index: 1000;
}

.edit-button {
  right: 20px;
}

.refresh-button {
  right: 20px;
}

iframe {
  border: none;
}
</style>