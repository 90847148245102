<template>
    <v-card maxWidth="1124" class="mx-auto mt-10 pa-10" elevation="0">
      <v-row no-gutter>
      <v-col cols="9" >
        <v-card class="justify-center mx-auto Position-sticky top-10" elevation="0">
      <v-card-title>My Blog Form</v-card-title>
      <v-card-text>
        <CoverImageUpload @imageUploaded="handleImageUploaded" />
        <v-text-field class="Blog-Title" flat  variant="solo" v-model="name" label="Titulo do Blog"></v-text-field>
        <v-textarea class="mb-10" :counter="180" rows="3" persistent-counter variant="solo" flat v-model="surname" label="Breve descrição do blog"></v-textarea>
       
  
        <QuillEditor style="height: 300px;" v-model="editorContent" theme="snow" :modules="modules" toolbar="full" @ready="onEditorReady" />
      </v-card-text>
   
    </v-card>
      </v-col>
      <v-col cols="3">
        <v-card variant="outlined"  elevation="0">
          <v-card-title>Publicar</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-select density="compact" variant="outlined" multiple v-model="gender" :items="genders" label="Blog categoria"></v-select>
           
            <v-btn class="my-3" block @click="saveContent" color="indigo-accent-4">Publish Content</v-btn>    
            <v-btn block variant="outlined" color="indigo-accent-4"> save as draft</v-btn>
          </v-card-text>
   
     
        </v-card>
      </v-col>
    </v-row>
    </v-card>
  
  
  </template>
  
  <script>
  import { defineComponent, ref } from 'vue';
  import { QuillEditor } from '@vueup/vue-quill';
  import BlotFormatter from 'quill-blot-formatter';
  import '@vueup/vue-quill/dist/vue-quill.snow.css';
  import '@vueup/vue-quill/dist/vue-quill.bubble.css';
  import '@vueup/vue-quill/dist/vue-quill.snow.css';
  import axios from '@/axios';
  import CoverImageUpload from './CoverImageUpload.vue';
  
  
  export default defineComponent({
    components: {
      QuillEditor,
      CoverImageUpload,
    },
    setup() {
      const name = ref('');
      const surname = ref('');
      const gender = ref('');
      const editorContent = ref(null); // Initialize as null
      const coverImageUrl = ref('');
      let quillInstance = null; // Hold the Quill instance
  
      const saveContent = async () => {
        try {
          if (quillInstance) {
            const delta = quillInstance.getContents();
            await axios.post('https://app.brendkit.com/saveBlog', {
              name: name.value,
              surname: surname.value,
              gender: gender.value,
              coverImage: coverImageUrl.value,
              content: delta,
            });
            console.log('Content saved successfully');
            clearEditor();
          } else {
            console.error('Quill editor instance not ready or undefined.');
          }
        } catch (error) {
          console.error('Error saving content:', error);
        }
      };
  
      const clearEditor = () => {
        name.value = '';
        surname.value = '';
        gender.value = '';
        coverImageUrl.value = '';
        if (quillInstance) {
          quillInstance.setContents([]); // Clear editor content
        }
      };
  
      const handleImageUploaded = (url) => {
        coverImageUrl.value = url;
      };
  
      const onEditorReady = (editorInstance) => {
        quillInstance = editorInstance; // Assign the Quill instance when ready
      };
  
      const modules = {
        module: BlotFormatter,
        options: {
          upload: (file) => {
            return new Promise((resolve, reject) => {
              const formData = new FormData();
              formData.append('image', file);
  
              axios.post('https://app.brendkit.com/upload-image', formData)
                .then((res) => {
                  console.log(res);
                  resolve(res.data.url);
                })
                .catch((err) => {
                  reject('Upload failed');
                  console.error('Error:', err);
                });
            });
          },
        },
      };
  
      const genders = ['Male', 'Female', 'Other']; // Example gender options
  
      return {
        name,
        surname,
        gender,
        editorContent,
        coverImageUrl,
        modules,
        genders,
        saveContent,
        clearEditor,
        handleImageUploaded,
        onEditorReady,
      };
    },
  });
  </script>
  
  <style scoped>
  .v-card--variant-outlined{
    border: 1px solid #e3e2e2 !important;
  }
  .card-sticky{
    position: sticky;
    top: 20%;
  }
  .Blog-Title{
    font-size: larger;
  }
  </style>
  