<template>
  <div id="app">
    <SubmissionPage />
  </div>
</template>

<script>
import SubmissionPage from "../components/SubmissionPage";
export default {
  components: {
    SubmissionPage,
  },
  data() {
    return {
     // Array to store data retrieved from the backend
    };
  },
};
</script>
<style scoped></style>
