<template>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :sort-by="[{ key: 'name', order: 'asc' }]"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Minhas Reservas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ props }">
              <v-btn class="mb-2" color="primary" dark v-bind="props">
                Adicionar Reserva
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12" sm="6">
                      <v-text-field hide-details v-model="editedItem.clientname" label="Client Name"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" sm="6">
                      <v-radio-group inline hide-details v-model="editedItem.clienttype" label="Client Type">
                        <v-radio label="Person" value="Person"></v-radio>
                        <v-radio label="Company" value="Company"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      <v-text-field hide-details v-model="editedItem.contact" label="Contact"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      <v-select hide-details v-model="editedItem.carbrand" :items="carBrands" label="Car Brand"></v-select>
                    </v-col>
                    <v-col cols="12" md="12" sm="6">
                      <v-text-field hide-details v-model="editedItem.email" label="Email Address" type="email"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      <v-text-field hide-details v-model="editedItem.startdate" label="Start Date" type="date"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      <v-text-field hide-details v-model="editedItem.enddate" label="End Date" type="date"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" sm="6">
                      <v-textarea hide-details v-model="editedItem.description" label="Description" rows="3"></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" variant="text" @click="close">Cancel</v-btn>
                <v-btn color="blue-darken-1" variant="text" @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" variant="text" @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="me-2" size="small" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon size="small" @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>
    </v-data-table>
  </template>
<script>
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      { title: 'Client Name', key: 'clientname' },
      { title: 'Client Type', key: 'clienttype' },
      { title: 'Contact', key: 'contact' },
      { title: 'Car Brand', key: 'carbrand' },
      { title: 'Email Address', key: 'email' },
      { title: 'Start Date', key: 'startdate' },
      { title: 'End Date', key: 'enddate' },
      { title: 'Description', key: 'description' },
      { title: 'Actions', key: 'actions', sortable: false },
    ],
    desserts: [], // This should be updated with real data
    editedIndex: -1,
    editedItem: {
      clientname: '',
      clienttype: '',
      contact: '',
      carbrand: '',
      email: '',
      startdate: '',
      enddate: '',
      description: '',
    },
    defaultItem: {
      clientname: '',
      clienttype: '',
      contact: '',
      carbrand: '',
      email: '',
      startdate: '',
      enddate: '',
      description: '',
    },
 
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nova reserva' : 'Editar reserva'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created () {
    this.initialize()
  },

  methods: {
    async initialize() {
      try {
        const response = await fetch('https://server.furnimoz.rent/api/rents');
        if (response.ok) {
          this.desserts = await response.json();
        } else {
          console.error('Failed to fetch rents');
        }
      } catch (error) {
        console.error('Error fetching rents:', error);
      }
    },

    async editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    async deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        const response = await fetch(`https://server.furnimoz.rent/api/rents/${this.editedItem._id}`, {
          method: 'DELETE',
        });
        if (response.ok) {
          this.desserts.splice(this.editedIndex, 1);
          this.closeDelete();
        } else {
          console.error('Failed to delete item');
        }
      } catch (error) {
        console.error('Error deleting item:', error);
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        // Update existing item
        try {
          const response = await fetch(`https://server.furnimoz.rent/api/rents/${this.editedItem._id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.editedItem),
          });
          if (response.ok) {
            const updatedItem = await response.json();
            Object.assign(this.desserts[this.editedIndex], updatedItem);
            this.close();
          } else {
            console.error('Failed to update item');
          }
        } catch (error) {
          console.error('Error updating item:', error);
        }
      } else {
        // Create new item
        try {
          const response = await fetch('https://server.furnimoz.rent/api/rents', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.editedItem),
          });
          if (response.ok) {
            const newItem = await response.json();
            this.desserts.push(newItem);
            this.close();
          } else {
            console.error('Failed to create item');
          }
        } catch (error) {
          console.error('Error creating item:', error);
        }
      }
    },
  },
}
</script>