<template>
  <div id="app">
    <StandardWebsites :items="imageData" />
  </div>
</template>

<script>
import StandardWebsites from "../components/StandardWebsites";
export default {
  components: {
    StandardWebsites,
  },
  data() {
    return {
      imageData: [], // Array to store data retrieved from the backend
    };
  },
};
</script>
<style scoped></style>
