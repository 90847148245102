<template>
    <div>
      <div
        class="cover-image-upload"
        :class="{ 'dragging-over': isDragging }"
        @dragover.prevent="handleDragOver"
        @dragleave.prevent="handleDragLeave"
        @drop.prevent="handleDrop"
      >
        <div v-if="previewUrl" class="cover-image-preview">
          <img :src="previewUrl" alt="Cover Image" class="cover-image" />
        </div>

        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
        
      </div>
      <p class="mb-5 text-caption" color="indigo-lighten-3">Image must be width: 744px by Height:209</p>
      <div class="upload-actions mx-auto mb-20">
        <v-btn variant="outlined" flat block @click="triggerFileInput" color="primary">Upload Cover Image</v-btn>
        <input type="file" ref="fileInput" @change="handleFileChange" style="display: none" accept="image/*" />
        <v-progress-linear v-if="uploadProgress > 0" :value="uploadProgress" striped color="blue"></v-progress-linear>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'CoverImageUpload',
    data() {
      return {
        previewUrl: null,
        selectedFile: null,
        imageUrl: '',
        uploadProgress: 0,
        isDragging: false,
        errorMessage: '', // Add a field for error messages
      };
    },
    methods: {
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
      handleFileChange(event) {
        const file = event.target.files[0];
        this.processFile(file);
      },
      handleDragOver() {
        this.isDragging = true;
      },
      handleDragLeave() {
        this.isDragging = false;
      },
      handleDrop(event) {
        this.isDragging = false;
        const file = event.dataTransfer.files[0];
        this.processFile(file);
      },
      handleUrlChange() {
        if (this.imageUrl) {
          this.previewUrl = this.imageUrl;
          this.$emit('imageUploaded', this.imageUrl);
        }
      },
      processFile(file) {
        if (file) {
          if (file.size > 5 * 1024 * 1024) { // Check if file size exceeds 5MB
            this.errorMessage = 'Image must be smaller than 5MB';
          } else {
            this.errorMessage = '';
            this.previewUrl = URL.createObjectURL(file);
            this.selectedFile = file;
            this.uploadImage(file);
          }
        }
      },
      async uploadImage(file) {
        const formData = new FormData();
        formData.append('image', file);
  
        try {
          const response = await axios.post('http://localhost:3000/upload-image', formData, {
            onUploadProgress: progressEvent => {
              this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }
          });
          console.log('Image uploaded successfully:', response.data.url);
          this.$emit('imageUploaded', response.data.url);
          this.uploadProgress = 0;
        } catch (error) {
          console.error('Error uploading image:', error);
          this.uploadProgress = 0;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .upload-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-bottom: 20px;
  }
  
  .cover-image-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-bottom: 5px;
    border: 2px dashed #ccc;   
    text-align: center;
    width: 100%;
    max-width: 800px;
    height: 210px;
    position: relative;
    background-color: rgb(236, 235, 235);
    transition: background-color 0.3s ease;
  }
  
  .cover-image-upload.dragging-over {
    background-color: #e0e0e0;
  }
  
  .cover-image-preview {
    margin-bottom: 10px;
    max-width: 800px;
    width: 100%;
    height: 210px;
  }
  
  .cover-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    
  }
  
  .error-message {
    color: red;
    font-weight: bold;
    margin-top: 10px;
  }
  </style>
  