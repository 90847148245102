<template>
    <div id="app">
      <EventosMatapa  />
    </div>
  </template>
  
  <script>
  import EventosMatapa from "../components/EventosMatapa";
  export default {
    components: {
      EventosMatapa,
    },
    data() {
      return {
       
      };
    },
  };
  </script>
  <style scoped></style>