<template>
  <v-app style="background-color: #F5F5F5;">
    <v-navigation-drawer
      v-model="drawer"
      v-if="showDrawer"
      :rail="rail"
      permanent
      class="px-2"
      color="indigo-accent-3"
      @click="rail = false"
    >
      <v-list-item
        prepend-avatar="https://uploads-ssl.webflow.com/6410539ee1db9e0068f283f2/6673315bc7b6c44830526366_icon_1print.svg"
        nav
      >
        BrendmyContent
      </v-list-item>

      <v-divider></v-divider>
      <v-list>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          :class="{ 'blue--text rounded': item.route === activeItem }"
          @click="navigateTo(item.route)"
        >
          <template v-slot:prepend>
            <v-icon>{{ item.icon }}</v-icon>
          </template>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar v-if="showAppBar" :elevation="2" style="border-radius: 8px; height:60px;" app>
      <v-app-bar-nav-icon @click.stop="rail = !rail"></v-app-bar-nav-icon>
      <template v-slot:append>
        <v-btn size="small" color="#22b28f" variant="outlined" icon="mdi-bell" class="mx-2"></v-btn>
        <v-btn @click="logout" size="small" color="#22b28f" variant="outlined" class="mx-2" icon="mdi-logout"></v-btn>
      </template>
    </v-app-bar>

    <v-main>
      <v-container class="grey-lighten-2" style="max-width: 100%">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      drawer: true,
      rail: false,
      activeItem: '',
      menuItems: [
        {
          title: "Dashboard",
          icon: "mdi-home",
          route: "/dashboard",
        },
        
        // {
        //   title: "Eventos",
        //   icon: "mdi-calendar-text",
        //   route: "/eventos",
        // },
        // {
        //   title: "Festival",
        //   icon: "mdi-calendar-text",
        //   route: "/festival",
        // },
        // {
        //   title: "Boutique",
        //   icon: "mdi-store",
        //   route: "/product-update",
        // },
        {
          title: "Motorize Cars",
          icon: "mdi-car",
          route: "/rent-a-car",
        },
        // {
        //   title: "Catering",
        //   icon: "mdi-motorbike",
        //   route: "/catering",
        // },
        // {
        //   title: "Cursos",
        //   icon: "mdi-book-open-page-variant",
        //   route: "/curso",
        // },
        // {
        //   title: "Udemy",
        //   icon: "mdi-book-open-page-variant",
        //   route: "/cursos",
        // },
        // {
        //   title: "Udemy",
        //   icon: "mdi-book-open-page-variant",
        //   route: "/cards",
        // },
        // {
        //   title: "Course details",
        //   icon: "mdi-book-open-page-variant",
        //   route: "/details",
          
        // },
        // {
        //   title: "Mercearia",
        //   icon: "mdi-basket",
        //   route: "/mercearia-page",
        // },
        {
          title: "FurniMoz",
          icon: "mdi-sofa",
          route: "/Mobilias",
        },

        // {
        //   title: "Media",
        //   icon: "mdi-access-point",
        //   route: "/media",
        // },
        // {
        //   title: "Cosméticos",
        //   icon: "mdi-guitar-pick",
        //   route: "/cosmeticos",
        // },

        // {
        //   title: "Newsletter",
        //   icon: "mdi-ballot",
        //   route: "/product-reservation",
        // },
        // {
        //   title: "Edit Website",
        //   icon: "mdi-ballot",
        //   route: "/websiteedit",
        // },
        {
          title: "Gerir Websites",
          icon: "mdi-ballot",
          route: "/websites",
        },
        // {
        //   title: "Reserve Car",
        //   icon: "mdi-ballot",
        //   route: "/ReserveCar",
        // },

        // {
        //   title: "Reservas",
        //   icon: "mdi-ballot",
        //   route: "/page-forms",
        // },
        // {
        //   title: "Blog posted",
        //   icon: "mdi-newspaper-variant-outline",
        //   route: "/Posted-Blogs",
        // },
        // {
        //   title: "Blogs",
        //   icon: "mdi-image-multiple",
        //   route: "/blog-post",
        // },
        {
          title: "Configurações",
          icon: "mdi-shape-polygon-plus",
          route: "/setting",
        },
      
      ],
    };
  },
  computed: {
    showDrawer() {
      // Hide drawer on login and register pages
      return !["/login", "/", "/register"].includes(this.$route.path);
    },
    showAppBar() {
      // Hide app bar on login and register pages
      return !["/login", "/", "/register"].includes(this.$route.path);
    },
  },
  methods: {
    navigateTo(route) {
      this.$router.push(route);
      this.activeItem = route;
    },
    logout() {
      // Clear token from local storage
      localStorage.removeItem("token");

      // Redirect to login page
      this.$router.push("/login");
    },
  },
  mounted() {
    this.activeItem = this.$route.path;
  },
  watch: {
    $route(to) {
      this.activeItem = to.path;
    },
  },
};
</script>
<style scoped>
.v-list-item--nav .v-list-item-title {
  font-size: 1.5rem !important;
}
.blue--text {
  background-color: #E8EAF6;
  color: #3F51B5;
}
</style>