<template>
  <div id="app">
    <CourseraPage :items="imageData" />
  </div>
</template>

<script>
import  CourseraPage from "../components/CourseraPage";
export default {
  components: {
    CourseraPage,
  },
  data() {
    return {
      imageData: [], // Array to store data retrieved from the backend
    };
  },
};
</script>
<style scoped></style>
