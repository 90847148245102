<template>
  <v-row>
    <!-- Loop through grouped programs, where each group contains programs with the same name -->
    <v-col
      v-for="(programGroup, index) in groupedPrograms"
      :key="index"
      cols="12"
      md="6"
    >
      <v-card>
        <v-card-title>
          <div v-if="programGroup[0].isEditing">
            <v-text-field
              v-model="programGroup[0].Name"
              label="Name"
              solo
            ></v-text-field>
          </div>
          <div v-else>
            {{ programGroup[0].Name }}
          </div>
        </v-card-title>
        <v-expansion-panels>
          <!-- Loop through each program within the current group -->
          <v-expansion-panel
            v-for="program in programGroup"
            :key="program._id"
          >
            <v-expansion-panel-title>
              <v-row no-gutters>
                <v-col class="d-flex justify-start" cols="4">
                  <div v-if="program.isEditing">
                    <v-text-field
                      v-model="program.weekday"
                      label="Weekday"
                      solo
                    ></v-text-field>
                  </div>
                  <div v-else>
                    {{ program.weekday }}
                  </div>
                </v-col>
                <v-col class="text--secondary" cols="8">
                  <v-row v-if="!program.isEditing">
                    <v-col class="d-flex justify-start" cols="6">
                      InicialTime: {{ program.InicialTime || "Not set" }}
                    </v-col>
                    <v-col class="d-flex justify-start" cols="6">
                      FinalTime: {{ program.FinalTime || "Not set" }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-row>
                <v-col cols="6">Dia da semana</v-col>
                <v-col cols="6" class="d-flex justify-end">
                  <div v-if="program.isEditing">
                    <v-text-field
                      v-model="program.weekday"
                      solo
                    ></v-text-field>
                  </div>
                  <div v-else>
                    {{ program.weekday }}
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">Horas</v-col>
                <v-col cols="6" class="d-flex justify-end">
                  <div v-if="program.isEditing">
                    InicialTime:
                    <v-text-field
                      v-model="program.InicialTime"
                      type="time"
                      solo
                    ></v-text-field>
                    FinalTime:
                    <v-text-field
                      v-model="program.FinalTime"
                      type="time"
                      solo
                    ></v-text-field>
                  </div>
                  <div v-else>
                    InicialTime:
                    {{ program.InicialTime || "Not set" }} FinalTime:
                    {{ program.FinalTime || "Not set" }}
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">Convidado</v-col>
                <v-col cols="6" class="d-flex justify-end">
                  <div v-if="program.isEditing">
                    <v-text-field
                      v-model="program.Convidado"
                      solo
                    ></v-text-field>
                  </div>
                  <div v-else>
                    {{ program.Convidado }}
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">Redes sociais</v-col>
                <v-col cols="6" class="d-flex justify-end">
                  <v-row class="d-flex justify-end">
                    <div v-if="program.isEditing">
                      <v-text-field
                      v-model="program.facebook"                     
                      solo
                    ></v-text-field>
                    </div>
                    <div v-else>
                      <v-btn                     
                      :href="program.facebook"
                      target="_blank"
                      size="small"
                      icon
                      variant="outlined"
                    >
                      <v-icon>mdi-facebook</v-icon>
                    </v-btn>
                    </div>
                   
                   
                    <v-btn
                      v-if="program.youtube"
                      :href="program.youtube.startsWith('http') ? program.youtube : `https://${program.youtube}`"
                      target="_blank"
                      size="small"
                      icon
                      variant="outlined"
                    >
                      <v-icon>mdi-youtube</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="program.instagram"
                      :href="program.instagram.startsWith('http') ? program.instagram : `https://${program.instagram}`"
                      target="_blank"
                      size="small"
                      icon
                      variant="outlined"
                    >
                      <v-icon>mdi-instagram</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="program.spotify"
                      :href="program.spotify.startsWith('http') ? program.spotify : `https://${program.spotify}`"
                      target="_blank"
                      size="small"
                      icon
                      variant="outlined"
                    >
                      <v-icon>mdi-spotify</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div v-if="program.isEditing">
                    <quill-editor
                    v-model="program.content"
                    theme="snow"
                    style="height: 200px"
                  ></quill-editor>

                  </div>
                  <div
                    v-else
                    class="quill-class"
                    v-html="renderContent(program.content)"
                  ></div>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
            <v-card-actions>
              <v-btn @click="toggleEditing(program, index)">
                {{ program.isEditing ? "Save" : "Edit" }}
              </v-btn>
              <v-btn @click="deleteProgram(program)" color="red">Delete</v-btn>
            </v-card-actions>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import axios from "axios";
import Quill from "quill";
import Delta from "quill-delta";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  components: {
    QuillEditor,
  },
  setup() {
    const programs = ref([]);

    const fetchProgramsData = async () => {
      try {
        const response = await axios.get("http://localhost:3000/api/programas");
        programs.value = response.data.map((program) => ({
          ...program,
          isEditing: false,
        }));
      } catch (error) {
        console.error("Error fetching programs data:", error);
      }
    };

    const groupedPrograms = computed(() => {
      return programs.value.reduce((groups, program) => {
        const group = groups.find(
          (group) => group[0].Name === program.Name
        );
        if (group) {
          group.push(program);
        } else {
          groups.push([program]);
        }
        return groups;
      }, []);
    });

    const convertDeltaToHtml = (delta) => {
      const quill = new Quill(document.createElement("div"));
      quill.setContents(delta);
      return quill.root.innerHTML;
    };

    const renderContent = (delta) => {
      if (!delta) return "";
      return convertDeltaToHtml(new Delta(JSON.parse(delta)));
    };
    const toggleEditing = (program) => {
  // Toggle editing mode for the specific program
  program.isEditing = !program.isEditing;

  // If entering edit mode, ensure Quill editor is populated with current content
  if (program.isEditing) {
    if (program.content) {
      // Parse the stored JSON content if it's in Delta format
      try {
        program.content = JSON.parse(program.content);
      } catch (error) {
        console.error("Error parsing content:", error);
      }
    } else {
      // Initialize with an empty Delta if no content exists
      program.content = { ops: [] };
    }
  } else {
    // Save changes when exiting edit mode
    updateProgram(program);
  }
};


const updateProgram = async (program) => {
  try {
    const updatedProgram = {
      ...program,
      content: JSON.stringify(program.content),
    };

    await axios.put(
      `http://localhost:3000/api/programas/${program._id}`,
      updatedProgram
    );
  } catch (error) {
    console.error("Error updating program:", error);
  }
};


    const deleteProgram = async (program) => {
      try {
        await axios.delete(
          `http://localhost:3000/api/programas/${program._id}`
        );
        fetchProgramsData();
      } catch (error) {
        console.error("Error deleting program:", error);
      }
    };

    onMounted(() => {
      fetchProgramsData();
    });

    return {
      programs,
      groupedPrograms,
      renderContent,
      toggleEditing,
      deleteProgram,
    };
  },
};
</script>

<style scoped>
.quill-class {
  height: auto;
  min-height: 200px;
}
</style>
