<template>
  <v-container>
    <!-- Campos fora do botão -->
    <v-row>
      <SelectTagseventComponent v-model="eventName" />
    </v-row>

    <!-- Formulário para múltiplos conjuntos de campos -->
    <v-form>
      <div v-for="(program, index) in programs" :key="index">
        <v-row v-if="program.showFields">
          <v-col cols="12">
            <v-select
              label="Selecione o dia da semana"
              :items="weekday"
              v-model="program.weekday"
              variant="outlined"              
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Nome da atividade"
              v-model="program.activityName"
              variant="outlined"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              type="time"
              label="Hora inicial"
              v-model="program.InicialTime"
              variant="outlined"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              type="time"
              label="Hora final"
              v-model="program.FinalTime"
              variant="outlined"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              label="Convidado"
              v-model="program.guest"
              variant="outlined"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              label="Facebook"
              v-model="program.facebook"
              variant="outlined"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              label="Instagram"
              v-model="program.instagram"
              variant="outlined"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              label="Youtube"
              v-model="program.youtube"
              variant="outlined"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              label="Spotify"
              v-model="program.spotify"
              variant="outlined"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-file-input
              label="Imagem do convidado"
              v-model="program.guestImage"
              variant="outlined"
              @change="previewImage(program, $event)"
            ></v-file-input>
          </v-col>

          <!-- Visualização da imagem selecionada -->
          <v-col cols="12" v-if="program.previewImageUrl">
            <v-img
              :src="program.previewImageUrl"
              alt="Imagem do convidado"
              height="200px"
            ></v-img>
          </v-col>

          <v-col cols="12" class="pb-10">
            <QuillEditor v-model="editorContent" @ready="onEditorReady" />
          </v-col>

          <v-col cols="12">
            <v-btn
              variant="text"
              size="small"
              @click="deleteProgram(index)"
              color="error"
              text
              icon
              ><v-icon>mdi-delete</v-icon>
              <v-tooltip color="primary" activator="parent" location="end">
                Apagar programa
              </v-tooltip>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider v-if="program.showFields"></v-divider>
      </div>
    </v-form>

    <!-- Botões para adicionar e salvar programas -->
    <v-row>
      <v-col cols="12">
        <v-btn icon variant="outlined" color="primary" @click="addProgram">
          <v-icon>mdi-plus</v-icon>
          <v-tooltip>Adicionar Novo Programa </v-tooltip>
        </v-btn>
        <div class="my-5"></div>
        <v-btn color="success" @click="savePrograms" class="ml-4">
          Salvar Todos
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import SelectTagseventComponent from "./SelectTagseventComponent.vue";

const eventName = ref("");
const weekday = [
  "Domingo",
  "Segunda",
  "Terça",
  "Quarta",
  "Quinta",
  "Sexta",
  "Sábado",
];
const editorContent = ref(null);
let quillInstance = null;

const programs = ref([]);
const previewImage = (program, event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      program.previewImageUrl = e.target.result;
    };
    reader.readAsDataURL(file);
  } else {
    program.previewImageUrl = null;
  }
};

const addProgram = () => {
  programs.value.push({
    activityName: "",
    weekday: "",
    InicialTime: "", // String vazia por padrão
    FinalTime: "", // String vazia por padrão
    guest: "",
    facebook: "",
    instagram: "",
    youtube: "",
    spotify: "",
    guestImage: null,
    showFields: true, // Certifique-se de que showFields é true
  });
};

const deleteProgram = (index) => {
  programs.value.splice(index, 1);
};
const onEditorReady = (editorInstance) => {
  quillInstance = editorInstance;
};

const savePrograms = async () => {
  try {
    // Prepare data for sending to the backend
    const formattedPrograms = programs.value.map((program) => {
      const formData = new FormData();
      formData.append("Name", eventName.value);
      formData.append("weekday", program.weekday);
      formData.append("Convidado", program.guest);
      formData.append("Facebook", program.facebook);
      formData.append("Instagram", program.instagram);
      formData.append("Youtube", program.youtube);
      formData.append("Spotify", program.spotify);
      formData.append("InicialTime", program.InicialTime);
      formData.append("FinalTime", program.FinalTime);
      formData.append("content", JSON.stringify(quillInstance.getContents()));

      if (program.guestImage) {
        formData.append("coverImage", program.guestImage);
      }

      return formData;
    });

    // Send data to the backend
    await Promise.all(
      formattedPrograms.map((formData) =>
        axios.post("http://localhost:3000/api/save-programas", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      )
    );

    console.log("Programas salvos com sucesso!");
    // Clear form or provide feedback
    savePrograms(); // Refresh the list after editing
  } catch (error) {
    console.error("Erro ao salvar programas:", error);
  }
};
</script>
