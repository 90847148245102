<template>

    <v-select
      v-model="selectedSubtipo"
      :items="flattenedSubtipo.map(subcat => subcat.name)"
      :item-text="subcat => subcat.name"
      :item-value="subcat => subcat.id"
      :menu-props="{ group: 'category' }"
      label="Tipo de evento"        
      dense
    ></v-select>

</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
export default {
  name: 'CategorySelector',
  setup() {
    const selectedSubtipo = ref([]);
    const flattenedSubtipo = ref([]);

    const fetchTiposAndSubtipo = async () => {
      try {
        const response = await axios.get('http://localhost:3000/api/tipos/all');
        const tipos = response.data.tipos;

        // Flatten subcategories array to make them selectable
        flattenedSubtipo.value = tipos.reduce((acc, tipo) => {
          return acc.concat(tipo.subtipo.map(sub => ({
            ...sub,
            tipo: tipo.name, // Add category property
            disabled: true, // Categories should be disabled
          })));
        }, []);
      } catch (error) {
        console.error('Error fetching categories and subcategories:', error);
      }
    };

    // Fetch data when component is mounted
    onMounted(fetchTiposAndSubtipo);

    return {
      selectedSubtipo,
      flattenedSubtipo,
    };
  },
};
</script>

<style scoped>
/* Add scoped styles here if needed */
</style>
