<template>
  <v-sheet class="mx-auto">
    <v-slide-group v-model="model"  selected-class="bg-primary">
      <v-slide-group-item
        v-for="n in 2"
        :key="n"
        v-slot="{ toggle, selectedClass }"
      >
        <v-card
          color=""
          :class="['ma-4', selectedClass]"
          height="150"
          variant="outlined"
          width="333.33"
          @click="toggle"
        >
          <v-card-title>{{ Title[n - 1] }}</v-card-title>
          <v-card-text>
            <p>{{ p[n - 1] }}</p>
          </v-card-text>
        </v-card>
      </v-slide-group-item>
    </v-slide-group>

    <v-expand-transition>
      <!-- <v-sheet v-if="model === 0" height="200">
        <v-card flat class="px-4">
          <v-row>
            <v-col cols="4">
              <v-card class="pa-2"><CategorySelect/></v-card></v-col>
               <v-col cols="4"> <v-card class="pa-2"><CategoryType /></v-card></v-col>
               <v-col cols="4"> 
                <v-card class="pa-2"><CategoryeventTags /></v-card>
               </v-col>
          </v-row>
       
        </v-card>
      </v-sheet> -->
      <!-- <v-sheet v-if="model === 1" height="200">
        <v-card flat>
          <v-row>
            
            <v-col cols="4"><v-card class="pa-4"><CategoryCourse/></v-card></v-col>
            <v-col cols="4"><v-card class="pa-4"><CategoryRegime /></v-card>  </v-col>
            <v-col cols="4"><v-card class="pa-4"><CategoryCursoTags /></v-card> </v-col>
            <v-col cols="4"><v-card class="pa-4"><ComboboxLanguage /></v-card> </v-col>
          </v-row>
       
        </v-card>
      </v-sheet> -->
      <v-sheet v-if="model === 0" height="200">
        <v-card flat>
          <v-row>
            
            <!-- <v-col cols="4"><v-card class="pa-4"><CategorySubmissionTitulo/></v-card></v-col> -->
            <v-col cols="4"><v-card class="pa-4"><CategoryBrand /></v-card></v-col>
           
            
        
                    </v-row>
       
        </v-card>
      </v-sheet>
      <v-sheet v-if="model === 1" height="200">
        <UsersPage/>
      </v-sheet>
    </v-expand-transition>
  </v-sheet>
</template>

<script>
// import CategoryCourse from './CategoryCourse.vue';
// import CategoryCursoTags from './CategoryCursoTags.vue';
// import CategoryeventTags from './CategoryeventTags.vue';

// import CategoryRegime from './CategoryRegime.vue';
// import CategorySelect from './CategorySelect';
// import ComboboxLanguage from './LanguageForm';
// import CategorySubmissionTitulo from './CategorySubmissionTitulo';
import CategoryBrand from './CategoryBrand';
// import CategoryType from './CategoryType';

import UsersPage from './UsersPage';


export default {
  components: {
    // CategorySelect,
    UsersPage,
    // ComboboxLanguage,
    // CategoryeventTags,
    // CategoryCursoTags,
    // CategoryCourse,
    // CategoryRegime,
    // CategoryType,
    // SelectBrand,
    // CategorySubmissionTitulo,
    CategoryBrand,
  },

  data: () => ({
    model: null,
  
    Title: [ "Carros", "Usuários", "Eventos",   "Submissões", "Usuários",],
    p: [
      "Use esta tela para adicionar campos dinâmicos",
      "Use esta tela para adicionar campos dinâmicos ",
      "Use esta tela para adicionar campos dinâmicos para os formulários Ex: Formulário submissão de projectos, Candidaturas etc",
      "Adicione e elimine usuários nesta tela, Ex: Gestores de conteúdo, Gestores de venda",
    ],
    search: "",
    
  }),
  methods: {
    toggleItem(n) {
      this.model = n;
    },
  },

};
</script>
