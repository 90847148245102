<template>
    <v-sheet class="mx-auto">
      <v-slide-group v-model="model"  selected-class="bg-primary">
        <v-slide-group-item
          v-for="n in 3"
          :key="n"
          v-slot="{ toggle, selectedClass }"
        >
          <v-card
            color=""
            :class="['ma-4', selectedClass]"
            height="80"
            variant="outlined"
            width="333.33"
            @click="toggle"
          >
            <v-card-title>{{ Title[n - 1] }}</v-card-title>
            <v-card-text>
              <p>{{ p[n - 1] }}</p>
            </v-card-text>
          </v-card>
        </v-slide-group-item>
      </v-slide-group>
  
      <v-expand-transition>
        <v-sheet v-if="model === 0" height="200">
          <v-card flat class="px-4">
            <v-row>
              <v-col cols="12">
                <v-card class="pa-2"><FurniMozPage/></v-card>
            </v-col>
          
                 
            </v-row>
         
          </v-card>
        </v-sheet>
        <v-sheet v-if="model === 1" height="200">
          <v-card flat>
            <v-row>
              <v-col cols="12">
                <v-card class="pa-2"><AvenguerPage/></v-card>
            </v-col>
          
                 
            </v-row>
         
          </v-card>
        </v-sheet>
        <v-sheet v-if="model === 2" height="200">
          <v-card flat>
            <v-row>
              <v-col cols="12">
                <v-card class="pa-2"><MotorizePage/></v-card>
            </v-col>
          
                 
            </v-row>
         
          </v-card>
        </v-sheet>
  
      </v-expand-transition>
    </v-sheet>
  </template>
  
  <script>
import FurniMozPage from '../components/FurniMozPage'
import AvenguerPage from '../components/AvenguerPage'
import MotorizePage from '../components/MotorizePage'
  
  export default {
    components: {
        FurniMozPage,
        AvenguerPage,
        MotorizePage,

    },
  
    data: () => ({
      model: null,
    
      Title: ["Furnimoz", "Avenguer", "Motorize"],
      p: [
        "Edit a página Furnimoz aqui",
        "Edit a página Avenguer aqui",
        "Edit a página Motorize aqui",

      ],
      search: "",
      
    }),
    methods: {
      toggleItem(n) {
        this.model = n;
      },
    },
  
  };
  </script>
  