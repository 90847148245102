<template>
  <v-sheet class="mx-auto">
    <v-slide-group v-model="model"  selected-class="bg-primary">
      <v-slide-group-item
        v-for="n in 3"
        :key="n"
        v-slot="{ toggle, selectedClass }"
      >
        <v-card
          color=""
          :class="['ma-4', selectedClass]"
          height="150"
          variant="outlined"
          width="333.33"
          @click="toggle"
        >
          <v-card-title>{{ Title[n - 1] }}</v-card-title>
          <v-card-text>
            <p>{{ p[n - 1] }}</p>
          </v-card-text>
        </v-card>
      </v-slide-group-item>
    </v-slide-group>

    <v-expand-transition>
      <!-- <v-sheet v-if="model === 0" height="200">
        <v-card flat>
       <ProjectPage/>
        </v-card>
      </v-sheet> -->
      <!-- <v-sheet v-if="model === 1" height="200">
        <v-card flat>
       <CandidatePage />
        </v-card>
      </v-sheet> -->
      <v-sheet v-if="model === 0" height="200">
        <v-card flat>
       <ReservePage/>
        </v-card>
      </v-sheet>
      <v-sheet v-if="model === 1" height="200">
        <v-card flat>
       <ReseveCar/>
        </v-card>
      </v-sheet>
      <v-sheet v-if="model === 2" height="200">
        <v-card flat>
       <SubscribePage/>
        </v-card>
      </v-sheet>
      <v-sheet v-if="model === 5" height="200">
        <v-card flat>
       <DynamicChip/>
        </v-card>
      </v-sheet>
     
    </v-expand-transition>
  </v-sheet>
</template>

<script>
// import CandidatePage from './CandidatePage.vue';
// import ProjectPage from './ProjectPage.vue';
import ReservePage from './ReservePage.vue';
import ReseveCar from './ReserveCar.vue';
import SubscribePage from './SubscribePage.vue';
import DynamicChip from './DynamicChip.vue';

export default {
  components: {
    // CandidatePage,
    ReservePage,
    // ProjectPage,
    ReseveCar,
    SubscribePage,
    DynamicChip,
  },

  data: () => ({
    model: null,
  
    Title: ["FurniMoz", "Reservas de carro", "Subscritos", "Dynamic Chip", "Projectos", "Candidaturas"],
    p: [
    
      "Esta tabela visualiza todas os susbcritos a newsletters etc",
      "Esta tabela visualiza todos os Projectos submetidos",
      "Esta tabela visualiza Todas as reservas de mobilias ou outros serviços vendidos no website",
      "Esta tabela visualiza Todas as reservas de carros ou outros serviços vendidos no website",
    ],
    search: "",
    
  }),
  methods: {
    toggleItem(n) {
      this.model = n;
    },
  },

};
</script>
