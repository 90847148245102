<template>
  <div id="app">
    <EventosPage :items="imageData" />
  </div>
</template>

<script>
import EventosPage from "../components/EventosPage";
export default {
  components: {
    EventosPage,
  },
  data() {
    return {
      imageData: [], // Array to store data retrieved from the backend
    };
  },
};
</script>
<style scoped></style>