<template>
  <div id="app">
    <MerceariaPage :items="imageData" />
  </div>
</template>

<script>
import MerceariaPage from "../components/MerceariaPage";
export default {
  components: {
    MerceariaPage,
  },
  data() {
    return {
      imageData: [], // Array to store data retrieved from the backend
    };
  },
};
</script>
<style scoped></style>
