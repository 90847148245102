<template>
  <div id="app">
    <RentAcar :items="imageData" />
  </div>
</template>

<script>
import RentAcar from "../components/RentAcar";
export default {
  components: {
    RentAcar,
  },
  data() {
    return {
      imageData: [], // Array to store data retrieved from the backend
    };
  },
};
</script>
<style scoped></style>
