<template>
    <div id="app">
      <SettingPage />
    </div>
  </template>
  
  <script>
  import SettingPage from "../components/SettingPage";
  export default {
    components: {
      SettingPage,
    },
    data() {
      return {
     // Array to store data retrieved from the backend
      };
    },
  };
  </script>
  <style scoped></style>
  