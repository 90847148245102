<template>
  <div id="app">
    <FestivalPage :items="imageData" />
  </div>
</template>

<script>
import FestivalPage from "../components/FestivalPage";
export default {
  components: {
    FestivalPage,
  },
  data() {
    return {
      imageData: [], // Array to store data retrieved from the backend
    };
  },
};
</script>
<style scoped></style>