<template>
    <v-container>
        <v-btn icon size="small" variant="outlined" @click="goBack" class="mb-4">
            <v-icon left>mdi-arrow-left</v-icon>          
          </v-btn>
      <v-row>
        
        <v-col cols="12" md="8">
          <!-- Back Button -->
          <v-card class="pa-3">
            <v-col v-for="(image, index) in course.coverImage" :key="index" cols="12">
            <v-img :src="image.path" cover></v-img>
          </v-col>
          <v-card-title>{{ course.productName }}</v-card-title>
          <v-card-subtitle>Price: ${{ course.price }}</v-card-subtitle>
          <div style="padding: 20px;" :modules="modules" class="quill-class" v-html="renderContent(course.content)">
            
          </div>
        </v-card>
  
          
        </v-col>
        <v-col cols="12" md="4">
            <v-card class="pa-3">
                <v-expansion-panels flat v-if="course.units.length > 0" :elevation="0">
                <v-expansion-panel
                v-for="(unit, index) in course.units"
                    :key="index"
                >
                <v-expansion-panel-title>
                 
                    <v-avatar size="small" class="mr-5">
                        {{ index + 1 }}
                    </v-avatar>
                            Módulo  {{ unit.title }}
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <v-card tile :elevation="0">
                        <v-card-title class="text-h6">{{ unit.title }}</v-card-title>
                        <v-card-text>{{ unit.intro }}</v-card-text>
                        <v-card-text>{{ unit.hours }}</v-card-text>
                        <v-divider class="my-4"></v-divider>

                        <!-- Iterate over includes and display videos, images, and documents -->
                        <div v-for="(include, includeIndex) in unit.includes" :key="includeIndex">
                        <v-row>
                            <v-col>
                                <h4>{{ include.name }}</h4>
                            </v-col>
                            <v-col class="d-flex justify-end">
                                <p>Total:{{ include.quantity }}</p>
                            </v-col>
                        </v-row>
                       
                        

                        <!-- Display Videos -->
                        <div v-if="include.videos.length">
                           
                            <v-row>
                            <v-col v-for="(video, videoIndex) in include.videos" :key="videoIndex" cols="12">
                                <video :src="video.path" controls width="100%"></video>
                                <p>{{ video.caption }}</p>
                            </v-col>
                            </v-row>
                        </div>

                        <!-- Display Images -->
                        <div v-if="include.images.length">
                       
                            <v-row>
                            <v-col v-for="(image, imageIndex) in include.images" :key="imageIndex" cols="12">
                                <v-img :src="image.path" height="200" cover></v-img>
                                <p>{{ image.caption }}</p>
                            </v-col>
                            </v-row>
                        </div>

                        <!-- Display Documents -->
                        <div v-if="include.documents.length">
                       
                            <v-row>
                            <v-col v-for="(document, documentIndex) in include.documents" :key="documentIndex" cols="12">
                                <a :href="document.path" target="_blank">{{ document.filename }}</a>
                                <p>{{ document.caption }}</p>
                            </v-col>
                            </v-row>
                        </div>

                        <v-divider></v-divider>
                        </div>
                    </v-card>
                    </v-expansion-panel-text>
                </v-expansion-panel>
                </v-expansion-panels>
            </v-card>
         
        </v-col>
      </v-row>
      <v-row>





 
      </v-row>
    </v-container>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import axios from 'axios';
  import Delta from 'quill-delta';
  import BlotFormatter from 'quill-blot-formatter'
  import Quill from 'quill';

  import '@vueup/vue-quill/dist/vue-quill.snow.css';

  
  const route = useRoute();
  const router = useRouter();
  const courseId = route.params.id;
  
  const course = ref({

    units: [],
});
const modules = ref({
      name: 'blotFormatter',  
      module: BlotFormatter,
})

     // Function to convert Quill Delta to HTML
     const convertDeltaToHtml = (delta) => {
    const quill = new Quill(document.createElement('div'));
    quill.setContents(delta);
    return quill.root.innerHTML;
  };
  
  const renderContent = (delta) => {
    if (!delta) return '';
    return convertDeltaToHtml(new Delta(JSON.parse(delta)));
  };
  const fetchCourseDetails = async () => {
    try {
      const response = await axios.get(`http://localhost:3000/api/coursera/${courseId}`);
      course.value = response.data;
    } catch (error) {
      console.error('Error fetching course details:', error);
    }
  };
  
  const goBack = () => {
    router.push('/cards');
  };
  
  onMounted(() => {
    fetchCourseDetails();
  });
  </script>
  
  <style scoped>
  .v-img {
    width: 100%;
  }
  .quill-class li ul ol{
padding-left: 20px !important;
  }
  .quill-class ul {
padding-left: 20px;
  }
  .quill-class ol{
padding-left: 20px;
  }
  .quill-class {
padding: 20px !important;
  }
  </style>
  