<template>
  <div id="app">
    <LoginPage />
  </div>
</template>

<script>
import LoginPage from "../components/LoginPage";
export default {
  components: {
    LoginPage,
  },
  data() {
    return {};
  },
};
</script>
<style scoped></style>
