<template>
    <v-container>
      <!-- Course List -->
      <v-row>
        <v-col v-for="course in courses" :key="course._id" cols="12" md="4">
          <v-card @click="navigateToDetails(course._id)">
            <v-img v-for="(image, index) in course.coverImage" :key="index" :src="image.path" height="200" cover></v-img>
            <v-card-title>{{ course.productName }}</v-card-title>
            <v-card-subtitle>
              Units: {{ course.units.length }} | Price: ${{ course.price }} | Duration: {{ course.duration }} hours
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>


    </v-container>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import axios from 'axios';

  const courses = ref([]);


  const router = useRouter();
  
  const fetchCourses = async () => {
    try {
      const response = await axios.get('http://localhost:3000/api/coursera');
      courses.value = response.data;
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };
  
  const navigateToDetails = (courseId) => {
    router.push({ name: 'CourseDetails', params: { id: courseId } });
  };
  
  onMounted(() => {
    fetchCourses();
  });
  </script>
  
  <style scoped>
  .v-card {
    cursor: pointer;
  }
  </style>
  