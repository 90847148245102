<template>

  <div class="furniture-form">
    <h2>Registar viatura</h2>
    <v-form @submit.prevent="saveFurniture">
      <div class="cover-image-upload"
           :class="{ 'dragging-over': isDragging }"
           @dragover.prevent="handleDragOver"
           @dragleave.prevent="handleDragLeave"
           @drop.prevent="handleDrop">
        <div v-if="coverPreviewUrl" class="cover-image-preview">
          <img :src="coverPreviewUrl" alt="Cover Image" class="cover-image" />
        </div>
        <div v-if="coverErrorMessage" class="error-message">{{ coverErrorMessage }}</div>
      </div>
      <p class="mb-5 text-caption" color="indigo-lighten-3">Image must be width: 744px by Height:209</p>
      <div class="upload-actions mx-auto mb-20">
        <v-btn variant="outlined" flat block @click="triggerCoverFileInput" color="primary">Upload Cover Image</v-btn>
        <input type="file" ref="coverFileInput" @change="handleCoverFileChange" style="display: none" accept="image/*" />
        <v-progress-linear v-if="coverUploadProgress > 0" :value="coverUploadProgress" striped color="blue"></v-progress-linear>
      </div>

      <v-text-field v-model="productName" label="Product Name" required></v-text-field>
      
      <SelectBrand v-model="brandDetails" />
     
     
      <v-text-field v-model="price" label="Price" type="number" required></v-text-field>

      <h3>Upload Images</h3>
      <div v-for="(image, index) in images" :key="index" class="image-upload">
        <input type="file" @change="handleImageChange($event, index)" accept="image/*" />
        <v-text-field v-model="image.caption" label="Caption"></v-text-field>
        <v-img :src="image.url" class="dialog-image"></v-img>
        <v-btn size="32" icon @click="removeImage(index)" color="red">
          <v-icon size="xs">mdi-delete</v-icon>
        </v-btn>
      </div>
      <v-btn @click="addImageField">Add Image</v-btn>

      <h3>Content</h3>
      <QuillEditor v-model="editorContent" @ready="onEditorReady" />
     
      <v-btn  :loading="loading" type="submit" color="primary">Salavar viatura</v-btn>
    </v-form>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from '@/axios';
import { QuillEditor } from '@vueup/vue-quill';
import BlotFormatter from 'quill-blot-formatter';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import SelectBrand from './SelectBrand.vue';

export default {
  name: 'RentAcar',
  components: {
    QuillEditor,
    SelectBrand,
  },
  setup() {
    const productName = ref('');
    const brandDetails = ref({
      productcategory: '',
      productModal: '',
      seats: '',
    }); 
      
    const price = ref(0);
    const editorContent = ref(null);
    const loading = ref(false);
    const coverPreviewUrl = ref(null);
    const coverSelectedFile = ref(null);
    const coverUploadProgress = ref(0);
    const coverErrorMessage = ref('');
    const isDragging = ref(false);
    const images = ref([{ file: null, url: '', caption: '' }]);
    let quillInstance = null;

    const triggerCoverFileInput = () => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.addEventListener('change', handleCoverFileChange);
      input.click();
    };

    
    const handleCoverFileChange = (event) => {
      const file = event.target.files[0];
      processCoverFile(file);
    };

    const processCoverFile = (file) => {
      if (file) {
        if (file.size > 5 * 1024 * 1024) {
          coverErrorMessage.value = 'Image must be smaller than 5MB';
        } else {
          coverErrorMessage.value = '';
          coverPreviewUrl.value = URL.createObjectURL(file);
          coverSelectedFile.value = file;
        }
      }
    };

    const handleDragOver = () => {
      isDragging.value = true;
    };

    const handleDragLeave = () => {
      isDragging.value = false;
    };

    const handleDrop = (event) => {
      isDragging.value = false;
      const file = event.dataTransfer.files[0];
      processCoverFile(file);
    };

    const handleImageChange = (event, index) => {
      const file = event.target.files[0];
      if (file) {
        images.value[index].file = file;
        images.value[index].url = URL.createObjectURL(file);
      }
    };

    const addImageField = () => {
      images.value.push({ file: null, url: '', caption: '' });
    };

    const removeImage = (index) => {
      images.value.splice(index, 1);
    };

    const onEditorReady = (editorInstance) => {
      quillInstance = editorInstance;
    };

    const saveFurniture = async () => {
      loading.value = true; // Show progress bar
      const formData = new FormData();
      formData.append('productName', productName.value);
      formData.append('productModal', brandDetails.value.productModal);
      formData.append('productcategory', brandDetails.value.productcategory);
      formData.append('seats', brandDetails.value.seats);         
      formData.append('price', price.value);
      formData.append('content', JSON.stringify(quillInstance.getContents()));

      if (coverSelectedFile.value) {
        formData.append('coverImage', coverSelectedFile.value);
      }

      // Loop through each image and append the image file and its caption to formData
      for (let i = 0; i < images.value.length; i++) {
        if (images.value[i].file) {
          formData.append('images', images.value[i].file);
          formData.append('captions', images.value[i].caption); // Append the caption
        }
      }

      try {
        const response = await axios.post('https://server.furnimoz.rent/api/save-viatura', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('Furniture saved successfully:', response.data);
        clearEditor();
        loading.value = false; // Hide progress bar
      } catch (error) {
        console.error('Error saving furniture:', error);
        loading.value = false; // Hide progress bar in case of an error
      }
    };

    const clearEditor = () => {
      productName.value = '';
      brandDetails.value.productModal = '';
      brandDetails.value.productcategory = '';
      brandDetails.value.seats = '';        
      price.value = 0;
      coverPreviewUrl.value = null;
      coverSelectedFile.value = null;
      coverUploadProgress.value = 0;
      coverErrorMessage.value = '';
      images.value = [{ file: null, url: '', caption: '' }];
      if (quillInstance) {
        quillInstance.setContents([]);
      }
    };

    const modules = {
      module: BlotFormatter,
      options: {
        upload: (file) => {
          return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('image', file);

            axios.post('https://server.furnimoz.rent/api/upload-image', formData)
              .then((res) => {
                console.log(res);
                resolve(res.data.url);
              })
              .catch((err) => {
                reject('Upload failed');
                console.error('Error:', err);
                loading.value = false; // Hide progress bar in case of an error
              });
          });
        },
      },
    };

    return {
      productName,
      brandDetails, 
      price,
      coverPreviewUrl,
      coverUploadProgress,
      coverErrorMessage,
      isDragging,
      images,
      loading,
      handleDragOver,
      handleDragLeave,
      handleDrop,
      triggerCoverFileInput,
      handleCoverFileChange,
      handleImageChange,
      addImageField,
      removeImage,
      modules,
      editorContent,
      clearEditor,
      onEditorReady,
      saveFurniture,
    };
  },
};
</script>


<style scoped>
.v-form{
  width: 700px !important;
}
.furniture-form {
  
  margin: auto;
}
.cover-image-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-bottom: 5px;
  border: 2px dashed #ccc;   
  text-align: center;
  width: 100%;
  max-width: 800px;
  height: 210px;
  position: relative;
  transition: background-color 0.3s ease;
}
.cover-image-upload.dragging-over {
  background-color: #e0e0e0;
}
.cover-image-preview {
  margin-bottom: 10px;
  max-width: 800px;
  width: 100%;
  height: 210px;
}
.cover-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}
.image-upload {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.uploaded-image {
  width: 100%;
  max-width: 300px;
  margin-top: 10px;
}
</style>
