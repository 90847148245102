<template>
    <div id="app">
      <WebsiteEdit />
    </div>
  </template>
  
  <script>
  import WebsiteEdit from "../components/WebsiteEdit";
  export default {
    components: {
      WebsiteEdit,
    },
    data() {
      return {
       
      };
    },
  };
  </script>
  <style scoped></style>