<template>
  <div class="px-4">
      <v-row>
          <v-col>
              <v-card height="80" variant="text" color="green">
                  <v-card-title>
                      Total Sold Out 
                  </v-card-title>
                  <v-card-text>
                      <p>{{ calculateStatusTotal('Sold out') }}</p>
                  </v-card-text>
              </v-card>
          </v-col>
          <v-col>
              <v-card height="80" variant="text" color="blue">
                  <v-card-title>
                      Total Pending 
                  </v-card-title>
                  <v-card-text>
                      <p>{{ calculateStatusTotal('Pendente') }}</p>
                  </v-card-text>
              </v-card>
          </v-col>
          <v-col>
              <v-card height="80" variant="text" color="red">
                  <v-card-title>
                      Total Canceled 
                  </v-card-title>
                  <v-card-text>
                      <p>{{ calculateStatusTotal('Canceled') }}</p>
                  </v-card-text>
              </v-card>
          </v-col>
      </v-row>

    <v-data-table
      v-model:expanded.sync="expanded"
      :headers="dessertHeaders"
      :items="desserts"
      :search="search"
      item-value="clientName"
      show-expand
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
           <v-row class="d-flex justify-space-between">
              <v-col  cols="3">
                  Tabela de Reservas
              </v-col>
              <v-col  cols="3">
                  <v-text-field
                      v-model="search"
                      label="Search"
                      width="344"
                      prepend-inner-icon="mdi-magnify"
                      variant="outlined"
                      hide-details
                      density="compact"
                      single-line
                  ></v-text-field>
              </v-col>
           </v-row>
          </v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        <span>{{ calculateTotalPrice(item.products) }}</span>
      </template>
      <template v-slot:[`item.comprovativo`]="{ item }">
        <a :href="getComprovativoUrl(item)" target="_blank">Proof of payment</a>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-badge :color="statusColors[item.status]" :content="item.status"></v-badge>
      </template>
      <template v-slot:expanded-row="{ columns, item }">
        <tr>
          <td :colspan="columns.length">
            <v-card>
              <v-card-text>
                <v-row v-for="product in item.products" :key="product.name">
                  <v-col cols="2">
                    <v-avatar :image="product.coverImage" rounded="0" size="80"></v-avatar>
                  </v-col>
                  <v-col cols="6">
                    <p>{{ product.productName }}</p>
                  </v-col>
                  <v-col cols="4">
                    <p>{{ product.price }} MT</p>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text>
                <h4>Observações</h4>
                <p>{{ item.observations }}</p>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="confirmChangeStatus(item, 'Canceled')" :disabled="item.status === 'Sold out'">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="confirmChangeStatus(item, 'Sold out')" :disabled="item.status === 'Canceled'">Finalizar compra</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="red" @click="deleteReserva(item)">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>


<script>
import { ref, reactive, onMounted } from 'vue';
import axios from 'axios';

export default {
  setup() {
    const expanded = ref(null); // Use null to track only one expanded row at a time
    const search = ref(''); // Use null to track only one expanded row at a time
    const dessertHeaders = [
      { title: 'Nome do cliente', align: 'start', sortable: false, key: 'clientName' },
      { title: 'Contacto', key: 'contact' },
      { title: 'Email', key: 'email' },
      { title: 'Comprovativo', key: 'comprovativo' },
      { title: 'Localização', key: 'location' },
      { title: 'Itens', key: 'products.length' },
      { title: 'Valor total', key: 'price' },
      { title: 'Status', key: 'status' },
      { title: '', key: 'data-table-expand' },
    ];

    const desserts = reactive([]);
    const statusColors = {
      Pendente: 'blue',
      Canceled: 'red',
      'Sold out': 'green',
    };

    const confirmChangeStatus = async (item, newStatus) => {
      if (confirm(`Você realmente deseja mudar o status para ${newStatus}?`)) {
        try {
          const response = await axios.put(`https://server.furnimoz.rent/api/reservas/${item._id}/status`, { newStatus });
          item.status = response.data.status; // Update status in UI
        } catch (error) {
          console.error('Error updating status:', error);
        }
      }
    };

    const deleteReserva = async (item) => {
      if (confirm('Você realmente deseja deletar esta reserva?')) {
        try {
          await axios.delete(`https://server.furnimoz.rent/api/reservas/${item._id}`);
          const index = desserts.findIndex(dessert => dessert._id === item._id);
          if (index !== -1) {
            desserts.splice(index, 1); // Remove item from UI
          }
        } catch (error) {
          console.error('Error deleting reserva:', error);
        }
      }
    };

    const calculateTotalPrice = (products) => {
      return products.reduce((total, product) => total + product.price, 0) + 'MT';
    };

    const fetchReservas = async () => {
      try {
        const response = await axios.get('https://server.furnimoz.rent/api/reservas');
        desserts.push(...response.data);
      } catch (error) {
        console.error('Error fetching reservas:', error);
      }
    };

    const getComprovativoUrl = (item) => {
      return `https://server.furnimoz.rent/api/${item.comprovativoPath}`;
    };

    const calculateStatusTotal = (status) => {
      return desserts
        .filter(item => item.status === status)
        .reduce((total, item) => total + item.products.reduce((sum, product) => sum + product.price, 0), 0) + 'MT';
    };

    onMounted(() => {
      fetchReservas();
    });

    return {
      expanded,
      dessertHeaders,
      desserts,
      search,
      statusColors,
      confirmChangeStatus,
      deleteReserva,
      calculateTotalPrice,
      getComprovativoUrl,
      calculateStatusTotal,
    };
  },
};

</script>
