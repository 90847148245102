<template>
  <v-select
    v-model="selectedSubcourse"
    :items="flattenedSubcourse.map(subcat => subcat.name)"
    :item-text="subcat => subcat.name"
    :item-value="subcat => subcat.id"
    :menu-props="{ group: 'category' }"
    label="Nivel"
    dense
  ></v-select>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';

export default {
  name: 'CategorySelector',
  setup() {
    const selectedSubcourse = ref([]);
    const flattenedSubcourse = ref([]);

    const fetchCoursesAndSubcourse = async () => {
      try {
        const response = await axios.get('http://localhost:3000/api/courses/all');
        const courses = response.data.courses || [];

        // Flatten subcategories array to make them selectable
        flattenedSubcourse.value = courses.reduce((acc, course) => {
          if (Array.isArray(course.subcourse)) {
            return acc.concat(course.subcourse.map(sub => ({
              ...sub,
              course: course.name, // Add course name property
              disabled: false, // Set to false if subcourses should not be disabled
            })));
          }
          return acc;
        }, []);
      } catch (error) {
        console.error('Error fetching courses and subcourses:', error);
      }
    };

    // Fetch data when component is mounted
    onMounted(fetchCoursesAndSubcourse);

    return {
      selectedSubcourse,
      flattenedSubcourse,
    };
  },
};
</script>

<style scoped>
/* Add scoped styles here if needed */
</style>
