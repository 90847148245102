<template>
    <div id="app">
      <CourseDetails :items="imageData" />
    </div>
  </template>
  
  <script>
  import  CourseDetails from "../components/CourseDetails";
  export default {
    components: {
        CourseDetails,
    },
    data() {
      return {
        imageData: [], // Array to store data retrieved from the backend
      };
    },
  };
  </script>
  <style scoped></style>
  