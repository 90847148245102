<template>
  <v-dialog max-width="900">
    <template v-slot:activator="{ props }">
      <v-btn
        prepend-icon="mdi-plus"
        v-bind="props"
        text="Adicionar Evento"
      ></v-btn>
    </template>

    <template v-slot:default="{ isActive }">
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        bottom
        color="deep-purple-accent-4"
      ></v-progress-linear>
      <v-card class="py-4">
        <v-card-text align="center" justify="center"
          >Adicionar novo produto</v-card-text
        >
        <v-btn
          style="position: absolute; right: 0"
          class="float-right"
          icon
          variant="text"
          @click="isActive.value = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <v-card-text>
          <div>
            <v-row>
              <v-col>
                <div class="image-container">
                  <div
                    v-for="(image, index) in orderedImages"
                    :key="index"
                    class="image-square"
                  >
                    <img :src="image.url" alt="Uploaded Image" />
                    <v-btn
                      density="compact"
                      size="small"
                      icon="mdi-close"
                      @click="removeImage(index)"
                      class="remove-icon"
                    ></v-btn>
                  </div>
                </div>

                <!-- File input for uploading images -->
                <div class="input-container">
                  <v-card
                    class="py-4 rounded"
                    align="center"
                    justify="center"
                    color="#CFD8DC"
                    elevation="0"
                  >
                    <div align="center" justify="center">
                      <v-btn variant="tex">Clique para Carregar imagem</v-btn>
                    </div>
                  </v-card>
                  <input
                    type="file"
                    ref="fileInput"
                    @change="handleFileChange"
                    multiple
                    class="mb-4 hide-input"
                  />
                </div>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="productName"
                  type="text"
                  placeholder="Product Name"
                  variant="outlined"
                  density="comfortable"
                ></v-text-field>
                <v-text-field
                  v-model="productPrice"
                  type="text"
                  placeholder="Product Price"
                  variant="outlined"
                  density="comfortable"
                ></v-text-field>

                <!-- Multi-select for Productsize -->
                <v-select
                  v-model="productSize"
                  :items="productSizeOptions"
                  label="Product Size"
                  multiple
                  variant="outlined"
                  density="comfortable"
                ></v-select>

                <!-- Single select for Productcategory -->
                <v-select
                  v-model="productCategory"
                  :items="productCategoryOptions"
                  label="Product Category"
                  variant="outlined"
                  density="comfortable"
                ></v-select>

                <!-- Single select for Productstore -->
                <v-select
                  v-model="productStore"
                  :items="productStoreOptions"
                  label="Product Store"
                  variant="outlined"
                  density="comfortable"
                ></v-select>

                <v-container fluid>
                  <v-textarea
                    clearable
                    clear-icon="mdi-close-circle"
                    label="Text"
                    v-model="productDiscription"
                    variant="outlined"
                    density="comfortable"
                  ></v-textarea>
                </v-container>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-scale-transition>
            <div v-if="!loading" class="text-center"></div>
          </v-scale-transition>
          <v-btn @click="uploadImages">Salvar producto</v-btn>
        </v-card-actions>
      </v-card>
      <div v-if="showSuccessMessage" class="dialog">
        <v-card class="success-message py-8">
          <div align="center" justify="center">
            <v-btn color="#4CAF50" variant="outlined" icon="mdi-check"></v-btn>
          </div>

          <v-card-text align="center" justify="center">
            Produto adicionado com sucesso!
          </v-card-text>
        </v-card>
      </div>
    </template>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      uploadedImages: [],
      loading: false,
      showSuccessMessage: false,
      orderedImages: [],
      selectedFiles: null,
      productName: "",
      productDiscription: "",
      productPrice: "",
      productSize: [],
      productCategory: null,
      productStore: null,
      productSizeOptions: [
        "32",
        "34",
        "36",
        "38",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
      ],
      productCategoryOptions: ["Sapato", "Acessórios"],
      productStoreOptions: ["Loja Masculina", "Loja Feminina"],
    };
  },
  methods: {
    handleFileChange(event) {
      const files = event.target.files;

      // Ensure that selectedFiles is an array
      this.selectedFiles = this.selectedFiles || [];

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.uploadedImages.push({
            url: e.target.result,
            file: files[i],
          });
          this.orderImages(); // Update the ordered images
        };
        reader.readAsDataURL(files[i]);

        // Push each file to selectedFiles array
        this.selectedFiles.push(files[i]);
      }
    },
    orderImages() {
      // You can implement your custom logic for ordering images here
      // For example, you can use drag-and-drop functionality or other UI interactions
      this.orderedImages = [...this.uploadedImages];
    },
    removeImage(index) {
      this.uploadedImages.splice(index, 1);
      this.orderImages(); // Update the ordered images after removal
    },
    uploadImages() {
      console.log("Sending to database:", this.orderedImages);
      this.loading = true; // Show progress bar
      // Reset uploadedImages and orderedImages after sending to the database
      this.uploadedImages = [];
      this.orderedImages = [];
      const formData = new FormData();

      formData.append("productName", this.productName);
      formData.append(
        "productSize",
        JSON.stringify(this.productSize.map(Number))
      );
      formData.append("productCategory", this.productCategory);
      formData.append("productStore", this.productStore);
      formData.append("productPrice", this.productPrice);
      formData.append("productDiscription", this.productDiscription);

      // Append all files to the same "images" field
      for (let i = 0; i < this.selectedFiles.length; i++) {
        formData.append("images", this.selectedFiles[i]);
      }

      // Send a single request with all data
      fetch("https://app.brendkit.com/upload", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then(() => {
          this.loading = false; // Hide progress bar
          this.showSuccessMessage = true; // Show success message
          setTimeout(() => {
            this.showSuccessMessage = false; // Hide success message after a delay
            this.resetForm(); // Reset the form after hiding success message
          }, 3000); // Adjust the delay as needed
        })
        .catch((error) => {
          console.error("Error uploading products:", error);
          this.loading = false; // Hide progress bar in case of an error
        });
    },
    resetForm() {
      this.uploadedImages = [];
      this.orderedImages = [];
      this.selectedFiles = null;
      this.productName = "";
      this.productDiscription = "";
      this.productPrice = "";
      this.productSize = [];
      this.productCategory = null;
      this.productStore = null;
    },
  },
};
</script>
<style scoped>
.success-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image-container {
  display: flex;
  flex-wrap: wrap;
}

.image-square {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 10px;
  overflow: hidden;
}

.image-square img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-icon {
  position: absolute;
  top: 5px;
  right: 5px;

  color: white;
  cursor: pointer;

  background-color: rgba(0, 0, 0, 0.7);
}
.input-container {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 8px;

  cursor: pointer;
}
.hide-input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
}
.dialog {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.2);
}
</style>
