<template>
    <div id="app">
      <CourseCards :items="imageData" />
    </div>
  </template>
  
  <script>
  import  CourseCards from "../components/CourseCard";
  export default {
    components: {
        CourseCards,
    },
    data() {
      return {
        imageData: [], // Array to store data retrieved from the backend
      };
    },
  };
  </script>
  <style scoped></style>
  