<template>
  <div id="app">
    <RegisterUser />
  </div>
</template>

<script>
import RegisterUser from "../components/RegisterUser";
export default {
  components: {
    RegisterUser,
  },
  data() {
    return {};
  },
};
</script>
<style scoped></style>
