<template>

    <v-select
      v-model="selectedSubregime"
      :items="flattenedSubregime.map(subcat => subcat.name)"
      :item-text="subcat => subcat.name"
      :item-value="subcat => subcat.id"
      :menu-props="{ group: 'category' }"
      label="Regime"        
      dense
    ></v-select>

</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
export default {
  name: 'CategorySelector',
  setup() {
    const selectedSubregime = ref([]);
    const flattenedSubregime = ref([]);

    const fetchRegimesAndSubregime = async () => {
      try {
        const response = await axios.get('http://localhost:3000/api/regimes/all');
        const regimes = response.data.regimes;

        // Flatten subcategories array to make them selectable
        flattenedSubregime.value = regimes.reduce((acc, regime) => {
          return acc.concat(regime.subregime.map(sub => ({
            ...sub,
            regime: regime.name, // Add category property
            disabled: true, // Categories should be disabled
          })));
        }, []);
      } catch (error) {
        console.error('Error fetching courses and subcourses:', error);
      }
    };

    // Fetch data when component is mounted
    onMounted(fetchRegimesAndSubregime);

    return {
      selectedSubregime,
      flattenedSubregime,
    };
  },
};
</script>

<style scoped>
/* Add scoped styles here if needed */
</style>
