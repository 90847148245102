<template>
    <v-sheet class="mx-auto">
      <v-slide-group v-model="model"  selected-class="bg-primary">
        <v-slide-group-item
          v-for="n in 3"
          :key="n"
          v-slot="{ toggle, selectedClass }"
        >
          <v-card
            color=""
            :class="['ma-4', selectedClass]"
            height="150"
            variant="outlined"
            width="333.33"
            @click="toggle"
          >
            <v-card-title>{{ Title[n - 1] }}</v-card-title>
            <v-card-text>
              <p>{{ p[n - 1] }}</p>
            </v-card-text>
          </v-card>
        </v-slide-group-item>
      </v-slide-group>
  
      <v-expand-transition>
        <v-sheet v-if="model === 0" height="200">
          <v-card flat class="px-4">
            <v-row>
              <v-col cols="12">
                <v-card class="pa-2"><EventosPage/></v-card>
            </v-col>
          
                 
            </v-row>
         
          </v-card>
        </v-sheet>
        <v-sheet v-if="model === 1" height="200">
          <v-card flat>
         
         
          </v-card>
        </v-sheet>
  
      </v-expand-transition>
    </v-sheet>
  </template>
  
  <script>
import EventosPage from '../components/EventosPage.vue'
  
  export default {
    components: {
        EventosPage,

    },
  
    data: () => ({
      model: null,
    
      Title: ["Matapa", "Roots En Route", "Machavenga"],
      p: [
        "Os eventos da Matapa sao publicados aqui",
        "Os eventos do Roots são publicados aqui",
        "Os eventos do Machavenga são publicados aqui",

      ],
      search: "",
      
    }),
    methods: {
      toggleItem(n) {
        this.model = n;
      },
    },
  
  };
  </script>
  