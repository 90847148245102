<template>
  <div id="app">
    <UtopiaUpdate :items="imageData" />
  </div>
</template>

<script>
import UtopiaUpdate from "@/components/UtopiaUpdate.vue";
export default {
  components: {
    UtopiaUpdate,
  },
  data() {
    return {
      imageData: [], // Array to store data retrieved from the backend
    };
  },
};
</script>
<style scoped></style>
