<template>
  <div id="app">
    <ProductUpdate :items="imageData" />
  </div>
</template>

<script>
import ProductUpdate from "../components/ProductUpdate";
export default {
  components: {
    ProductUpdate,
  },
  data() {
    return {
      imageData: [], // Array to store data retrieved from the backend
    };
  },
};
</script>
<style scoped></style>
