<template>
    <v-btn
       @click="dialogOpen = true"
        prepend-icon="mdi-plus"     
        text="Add new product"
      ></v-btn>
  <v-dialog v-model="dialogOpen" max-width="900"> 

  
    
      <v-card class="py-4">
        <v-card-text align="center" justify="center"
          >Adicionar novo produto</v-card-text
        >
        <v-btn
          style="position: absolute; right: 0"
          class="float-right"
          icon
          variant="text"
          @click="dialogOpen = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <v-card-text>
          <div>
          
           
                <div class="image-container">
                  <div
                    v-for="(image, index) in orderedImages"
                    :key="index"
                    class="image-square"
                  >
                    <img :src="image.url" alt="Uploaded Image" />
                    <v-btn
                      density="compact"
                      size="small"
                      icon="mdi-close"
                      @click="removeImage(index)"
                      class="remove-icon"
                    ></v-btn>
                  </div>
                </div>

                <!-- File input for uploading images -->
                <div class="input-container">
                  <v-card
                    class="py-4 rounded"
                    align="center"
                    justify="center"
                    color="#CFD8DC"
                    elevation="0"
                  >
                    <div align="center" justify="center">
                      <v-btn variant="tex">Clique para Carregar imagem</v-btn>
                    </div>
                  </v-card>
                  <input
                    type="file"
                    ref="fileInput"
                    @change="handleFileChange"
                    multiple
                    class="mb-4 hide-input"
                  />
                </div>
             
            
                <v-text-field
                  v-model="productName"
                  type="text"
                  placeholder="Nome do produto"
                  variant="outlined"
                  density="comfortable"
                ></v-text-field>
                <v-text-field
                  v-model="productPrice"
                  type="text"
                  placeholder="Preço"
                  variant="outlined"
                  density="comfortable"
                  
                ></v-text-field>

            
                <!-- Single select for Productcategory -->
                <v-select
                  v-model="productCategory"
                  :items="productCategoryOptions"
                  label="Categoria do produto"
                  variant="outlined"
                  density="comfortable"
                ></v-select>

              
                  <v-textarea
                    clearable
                    clear-icon="mdi-close-circle"
                    label="Breve descrição"
                    v-model="productDiscription"
                    variant="outlined"
                    density="comfortable"
                  ></v-textarea>
                  <v-textarea
                    clearable
                    clear-icon="mdi-close-circle"
                    label="Como Usar"
                    v-model="producthowto"
                    variant="outlined"
                    density="comfortable"
                  ></v-textarea>
                  <v-textarea
                    clearable
                    clear-icon="mdi-close-circle"
                    label="Ingridientes"
                    v-model="productIngridients"
                    variant="outlined"
                    density="comfortable"
                  ></v-textarea>
                  <v-textarea
                    clearable
                    clear-icon="mdi-close-circle"
                    label="Perguntas mais frequentes"
                    v-model="productFaqs"
                    variant="outlined"
                    density="comfortable"
                  ></v-textarea>
                  <v-textarea
                    clearable
                    clear-icon="mdi-close-circle"
                    label="Text"
                    v-model="productAdddetails"
                    variant="outlined"
                    density="comfortable"
                  ></v-textarea>
                  <p>Mais detalhes do produto</p>
                   <!-- Quill editor -->
                   <div id="editor"></div>
              
             
            
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-scale-transition>
            <div v-if="!loading" class="text-center"></div>
          </v-scale-transition>
          <v-btn @click="uploadImages">Salvar producto</v-btn>
        </v-card-actions>
      </v-card>
      <div v-if="showSuccessMessage" class="dialog">
        <v-card class="success-message py-8">
          <div align="center" justify="center">
            <v-btn color="#4CAF50" variant="outlined" icon="mdi-check"></v-btn>
          </div>

          <v-card-text align="center" justify="center">
            Produto adicionado com sucesso!
          </v-card-text>
        </v-card>
      </div>
   
  </v-dialog>
</template>

<script>
import Quill from 'quill/core';
import 'quill/dist/quill.snow.css';

// Import required Quill modules
import Toolbar from 'quill/modules/toolbar';
import Snow from 'quill/themes/snow';
import Bold from 'quill/formats/bold';
import Italic from 'quill/formats/italic';
import Underline from 'quill/formats/underline';
import List from 'quill/formats/list';
import Link from 'quill/formats/link';
import Header from 'quill/formats/header';

Quill.register({
  'modules/toolbar': Toolbar,
  'themes/snow': Snow,
  'formats/bold': Bold,
  'formats/header': Header,
  'formats/italic': Italic,
  'formats/underline': Underline,
  'formats/list': List,
  'formats/link': Link
});

export default {
  data() {
    return {
      quill: null,
      uploadedImages: [],
      loading: false,
      showSuccessMessage: false,
      orderedImages: [],
      selectedFiles: null,
      productName: "",
      productDiscription: "",
      producthowto: "",
      productIngridients: "",
      productFaqs: "",
      productAdddetails: "",
      productPrice: "",    
      productCategory: null,  
      dialogOpen: false,     
      productCategoryOptions: ["Face Kit", "Body Kit"],
     
    };
  },
  watch: {
    dialogOpen(val) {
      if (val) {
        this.$nextTick(() => {
          if (!this.quill) {
            this.initializeQuill();
          }
        });
      }
    },
  },
  methods: {
    initializeQuill() {
      this.quill = new Quill('#editor', {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'link'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }]
          ]
        }
      });

      // Ensure Quill is fully initialized
      setTimeout(() => {
        this.quill.on('text-change', () => {
          // Optional: Handle text change events if needed
        });
      }, 100);
    },
    handleFileChange(event) {
      const files = event.target.files;

      // Ensure that selectedFiles is an array
      this.selectedFiles = this.selectedFiles || [];

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.uploadedImages.push({
            url: e.target.result,
            file: files[i],
          });
          this.orderImages(); // Update the ordered images
        };
        reader.readAsDataURL(files[i]);

        // Push each file to selectedFiles array
        this.selectedFiles.push(files[i]);
      }
    },
    orderImages() {
      // You can implement your custom logic for ordering images here
      // For example, you can use drag-and-drop functionality or other UI interactions
      this.orderedImages = [...this.uploadedImages];
    },
    removeImage(index) {
      this.uploadedImages.splice(index, 1);
      this.orderImages(); // Update the ordered images after removal
    },
    uploadImages() {
      if (!this.quill) {
        console.error('Quill editor is not initialized');
        return;
      }
      console.log("Sending to database:", this.orderedImages);
      this.loading = true; // Show progress bar
      // Reset uploadedImages and orderedImages after sending to the database
      this.uploadedImages = [];
      this.orderedImages = [];
      const delta = this.quill.getContents();
      const formData = new FormData();

      formData.append("productName", this.productName);
      formData.append("productCategory", this.productCategory);    
      formData.append("productPrice", this.productPrice);
      formData.append("productDiscription", this.productDiscription);
      formData.append("producthowto", this.producthowto);
      formData.append("productIngridients", this.productIngridients);
      formData.append("productFaqs", this.productFaqs);
      formData.append("productAdddetails", this.productAdddetails);
      formData.append('delta', JSON.stringify(delta));

      // Append all files to the same "images" field
      for (let i = 0; i < this.selectedFiles.length; i++) {
        formData.append("images", this.selectedFiles[i]);
      }

      // Send a single request with all data
      fetch("https://app.brendkit.com/cosmetics", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then(() => {
          this.loading = false; // Hide progress bar
          this.showSuccessMessage = true; // Show success message
          setTimeout(() => {
            this.showSuccessMessage = false; // Hide success message after a delay
            this.resetForm(); // Reset the form after hiding success message
          }, 3000); // Adjust the delay as needed
        })
        .catch((error) => {
          console.error("Error uploading products:", error);
          this.loading = false; // Hide progress bar in case of an error
        });
    },
    resetForm() {
      this.uploadedImages = [];
      this.orderedImages = [];
      this.selectedFiles = null;
      this.productName = "";
      this.productDiscription = "";
      this.producthowto = "";
      this.productIngridients = "";
      this.productFaqs = "";
      this.productAdddetails = "";
      this.productPrice = "";  
      this.productCategory = null;
  
    },
  },
};
</script>
<style scoped>
#editor{
  height: 300px;
  border-radius: 0 0 8px 8px;
}
.success-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image-container {
  display: flex;
  flex-wrap: wrap;
}

.image-square {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 10px;
  overflow: hidden;
}

.image-square img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-icon {
  position: absolute;
  top: 5px;
  right: 5px;

  color: white;
  cursor: pointer;

  background-color: rgba(0, 0, 0, 0.7);
}
.input-container {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 8px;

  cursor: pointer;
}
.hide-input {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
}
.dialog {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.2);
}
</style>
