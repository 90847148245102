<template>
    <v-autocomplete
       id="subcategory-select"
       v-model="selectedSubcategories"
       label="Categoria"
       multiple
       dense
       clearable
       chips
       filled
       :items="flattenedSubcategories.map(subcat => subcat.name)"
       :item-text="subcat => subcat.name"
       :item-value="subcat => subcat.id"
       :menu-props="{ group: 'category' }"
     >
   </v-autocomplete>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
export default {
 name: 'CategorySelector',
 setup() {
   const selectedSubcategories = ref([]);    
   const flattenedSubcategories = ref([]);

   const fetchCategoriesAndSubcategories = async () => {
       try {
         const response = await axios.get('http://localhost:3000/api/categories/all');
         const categories = response.data.categories;
 
         // Flatten subcategories array to make them selectable
         flattenedSubcategories.value = categories.reduce((acc, category) => {
           return acc.concat(category.subcategories.map(sub => ({
             ...sub,
             category: category.name, // Add category property
             disabled: true, // Categories should be disabled
           })));
         }, []);
       } catch (error) {
         console.error('Error fetching categories and subcategories:', error);
       }
     };

 
   // Fetch data when component is mounted
   onMounted(fetchCategoriesAndSubcategories);

   return {
       selectedSubcategories,
     flattenedSubcategories,
   };
 },
};
</script>

<style scoped>
/* Add scoped styles here if needed */
</style>