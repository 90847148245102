<template>
   <v-dialog v-model="dialogVisible"  max-width="1024px" >

    <BlogPost />
 

  </v-dialog>
    <v-card>
    <v-data-iterator

      :items="contents"
      :items-per-page="6"
      :search="search"
    >
      <template v-slot:header>
        <v-toolbar class="px-2">
            <v-row class="d-flex">
                <v-col>
                    <v-text-field
            v-model="search"
            density="comfortable"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
            style="max-width: 300px;"
            variant="solo"
            clearable
            hide-details
          ></v-text-field>
                </v-col>

                <v-col class="d-flex justify-end">
                <v-btn  prepend-icon="mdi-plus"
                    text="Add new product"
                        @click="dialogVisible = true"
                    ></v-btn>
                </v-col>
            </v-row>
         
        </v-toolbar>
      </template>

      <template v-slot:default="{ items }">
        <v-container class="pa-2" fluid>
          <v-row dense>
            <v-col
              v-for="item in items"
              :key="item.name"
              cols="auto"
              md="4"
            >
              <v-card class="pb-3" border flat>
                <v-img :src="item.raw.img"></v-img>

                <v-list-item :subtitle="item.raw.surname" class="mb-2">
                  <template v-slot:title>
                    <strong class="text-h6 mb-2">{{ item.raw.name }}</strong>
                  </template>
                </v-list-item>

                <div class="d-flex justify-space-between px-4">
                  <div class="d-flex align-center text-caption text-medium-emphasis me-1">
                    <v-icon icon="mdi-clock" start></v-icon>

                    <div class="text-truncate">{{ item.raw.gender }}</div>
                     <div v-html="convertDeltaToHtml(item.raw.content)"></div>
                  </div>

                  <v-btn
                    class="text-none"
                    size="small"
                    text="Read"
                    border
                    flat
                  >
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:footer="{ page, pageCount, prevPage, nextPage }">
        <div class="d-flex align-center justify-center pa-4">
          <v-btn
            :disabled="page === 1"
            density="comfortable"
            icon="mdi-arrow-left"
            variant="tonal"
            rounded
            @click="prevPage"
          ></v-btn>

          <div class="mx-2 text-caption">
            Page {{ page }} of {{ pageCount }}
          </div>

          <v-btn
            :disabled="page >= pageCount"
            density="comfortable"
            icon="mdi-arrow-right"
            variant="tonal"
            rounded
            @click="nextPage"
          ></v-btn>
        </div>
      </template>
    </v-data-iterator>
  </v-card>




  </template>
  
  <script>
  import { defineComponent, ref, onMounted } from 'vue';
  import axios from 'axios';
  import Quill from 'quill';
  import io from 'socket.io-client';
import BlogPost from './BlogPost.vue';

  
  export default defineComponent({
    components: {
      BlogPost
    },
    setup() {
        
      const contents = ref([]);
      const loading = ref(true);
      const error = ref(null);
      const show = ref(false);
      const search = ref('');
      const dialogVisible = ref('false');
      const socket = io('http://localhost:3000'); // Replace with your Socket.IO server URL
  
      // Function to convert Quill Delta to HTML
      const convertDeltaToHtml = (delta) => {
        const quill = new Quill(document.createElement('div'));
        quill.setContents(delta);
        return quill.root.innerHTML;
      };
         // Listen for updates from server
    socket.on('connect', () => {
      console.log('Connected to Socket.IO');
    });
  
      // Fetch content from server
      const fetchContent = async () => {
        try {
          console.log('Fetching content...');
          const response = await axios.get('http://localhost:3000/blog');
          console.log('Response received:', response.data);
          loading.value = false;
  
          // Log the detailed response structure
          console.log('Detailed response structure:', JSON.stringify(response.data, null, 2));
  
          // Check for an array of content items
          if (Array.isArray(response.data)) {
            contents.value = response.data;
            console.log('Rendered contents:', contents.value);
          } else {
            console.error('Unexpected response structure, expected an array:', response.data);
            error.value = 'Unexpected response structure. Please check the backend.';
          }
  
          loading.value = false;
        } catch (err) {
          console.error('Error fetching content:', err);
          error.value = 'Error fetching content. Please try again later.';
          loading.value = false;
        }
      };
      socket.on('contentsUpdated', (newContent) => {
      console.log('Received updated content:', newContent);
      contents.value.push(newContent);
    });
   // Example: Emit updates to server
   const updateContents = (newContent) => {
      socket.emit('updateContents', newContent);
    };
  
  
      // Fetch content when component is mounted
      onMounted(() => {
        fetchContent();
       
      });
  
      return {
        contents,
        show,
        dialogVisible,
        loading,
        updateContents,
        search,
        error,
        convertDeltaToHtml
      };
    }
  });
  </script>
  
  <style scoped>
  .content-item {
    margin-bottom: 20px;
  }
  .ql-editor {
    min-height: 200px;
  }
  </style>
  