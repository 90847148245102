<template>
  <div class="iframe-container">
    <!-- Navigation buttons for pages -->
    <v-btn variant="outlined" color="blue" class="mr-5 mb-5" v-for="(page, index) in pages" :key="index" @click="navigateToPage(page.path)">
      Go to {{ page.path }}
    </v-btn>

    <!-- Edit Button -->
    <v-btn color="blue" @click="toggleEditMode" class="edit-button">
      {{ editMode ? 'Exit Edit Mode' : 'Enter Edit Mode' }}
    </v-btn>

    <!-- Refresh Button -->
    <!-- <v-btn @click="refreshPage" class="refresh-button">
      Refresh Page
    </v-btn> -->

    <!-- Debug URL Display -->
    <!-- <div class="debug-overlay">
      <p>Current URL: {{ currentIframeUrl }}</p>
    </div> -->

    <!-- Iframes for different pages -->
    <iframe
      v-for="(page, index) in pages"
      :key="index"
      :src="page.iframeSrc"
      :style="{ display: currentPage === page.path ? 'block' : 'none' }"
      width="100%"
      height="100%"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editMode: false,
      currentPage: '/', // Tracks which page is being displayed
      pages: [
        {
          path: '/', // Root page
          iframeSrc: 'https://furnimoz.rent'
        },
        {
          path: '/about', // Another page
          iframeSrc: 'https://furnimoz.rent/about'
        },
        {
          path: '/packages', // Another page
          iframeSrc: 'https://furnimoz.rent/packages'
        },
        {
          path: '/terms', // Another page
          iframeSrc: 'https://furnimoz.rent/terms'
        }
      ]
    };
  },
  computed: {
    currentIframeUrl() {
      const currentPageData = this.pages.find(page => page.path === this.currentPage);
      return currentPageData ? currentPageData.iframeSrc : '';
    }
  },
  methods: {
    toggleEditMode() {
      this.editMode = !this.editMode;

      // Update only the iframeSrc for the current page
      const pageIndex = this.pages.findIndex(page => page.path === this.currentPage);
      if (pageIndex !== -1) {
        const url = new URL(this.pages[pageIndex].iframeSrc);
        if (this.editMode) {
          // Add edit mode
          url.searchParams.set('edit', 'true');
        } else {
          // Remove edit mode
          url.searchParams.delete('edit');
        }

        // Update the iframe source for the current page
        this.pages[pageIndex].iframeSrc = url.toString();
      }
    },
    refreshPage() {
      // Refresh the currently visible iframe by appending a dummy query string
      const pageIndex = this.pages.findIndex((p) => p.path === this.currentPage);
      if (pageIndex !== -1) {
        this.pages[pageIndex].iframeSrc += '';
      }
    },
    navigateToPage(path) {
      this.currentPage = path; // Update the current page

      // Ensure the correct URL is set based on the current page
      const pageIndex = this.pages.findIndex(page => page.path === this.currentPage);
      if (pageIndex !== -1) {
        const currentIframe = this.pages[pageIndex];

        // If in edit mode, ensure the URL includes the 'edit=true' parameter
        if (this.editMode) {
          const url = new URL(currentIframe.iframeSrc);
          url.searchParams.set('edit', 'true');
          this.pages[pageIndex].iframeSrc = url.toString();
        }
      }
    }
  }
};
</script>

<style scoped>
iframe {
  height: 100vh;
  border: none;
}

.iframe-container {
  position: relative;
}

.edit-button,
.refresh-button {
  z-index: 1000;
}

.edit-button {
  position: absolute;
  right: 20px;
  top: 10px;
}

.refresh-button {
  position: absolute;
  right: 120px;
  top: 20px;
}

.debug-overlay {
  position: absolute;
  top: 60px;
  left: 20px;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  padding: 10px;
  border-radius: 4px;
}
</style>
