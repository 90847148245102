<template>

  <v-btn  prepend-icon="mdi-plus"
     text="Add new product"
        @click="dialogVisible = true"
      ></v-btn>
  <v-dialog v-model="dialogVisible"  max-width="800px" >
    <v-progress-linear :active="loading" :indeterminate="loading" bottom color="deep-purple-accent-4"></v-progress-linear>
    <v-card>
      <v-btn
      style="z-index: 999;position: absolute; right: 0"
      class="float-right"
       icon
       variant="text"
       @click="dialogVisible = false"
      ><v-icon>mdi-close</v-icon></v-btn>
      <v-card-text>
        <v-card class="py-4" elevation="0">
          <v-card-text align="center" justify="center">Formulário de Eventos</v-card-text>
         
          <v-card-text>
            <div>
              <v-row>
                <v-col>
                  <div class="image-container">
                  <div
                    v-for="(image, index) in orderedImages"
                    :key="index"
                    class="image-square"
                  >
                    <img :src="image.url" alt="Uploaded Image" />
                    <v-btn
                      density="compact"
                      size="small"
                      icon="mdi-close"
                      @click="removeImage(index)"
                      class="remove-icon"
                    ></v-btn>
                   
                  </div>
                </div>
                 <!-- File input for uploading images -->
                 <div class="input-container">
                  <v-card
                    class="py-4 rounded"
                    align="center"
                    justify="center"
                    color="#CFD8DC"
                    elevation="0"
                  >
                    <div align="center" justify="center">
                      <v-btn variant="tex">Clique para Carregar imagem</v-btn>
                    </div>
                  </v-card>
                  <input
                    type="file"
                    ref="fileInput"
                    @change="handleFileChange"
                    multiple
                    class="mb-4 hide-input"
                  />
                </div>
                  <!-- Event title -->
                  <v-text-field v-model="productName" type="text" placeholder="Título do Evento" variant="outlined" density="comfortable"></v-text-field>

                  <!-- Event description -->
                  <v-textarea clearable clear-icon="mdi-close-circle" label="Breve Descrição do evento" v-model="productDescription" variant="outlined" density="comfortable" maxlength="150"></v-textarea>

                  <!-- Event location -->
                  <v-text-field v-model="productLocal" type="text" placeholder="Local do evento" variant="outlined" density="comfortable"></v-text-field>

                  <!-- Event date and time -->
                  <v-row>
                    <v-col cols="6">
                      <v-text-field v-model="productExpirationDate" type="date" label="Data do evento" variant="outlined" density="comfortable"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field v-model="eventTime" type="time" label="Hora do evento" variant="outlined" density="comfortable"></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- Event type -->
                  <v-select v-model="productTipo" :items="productTipoOptions" label="Tipo do evento" multiple variant="outlined" density="comfortable"></v-select>

                  <!-- Guest name -->
                  <v-text-field v-model="productGuest" type="text" placeholder="Nome do convidado" variant="outlined" density="comfortable"></v-text-field>

                  <!-- Event address -->
                  <v-text-field v-model="productAddress" type="text" placeholder="Endereço" variant="outlined" density="comfortable"></v-text-field>

                  <!-- More event details -->              
                   
                    <p class="text-subtitle pb-5">Mais detalhes do evento</p>
                    <!-- Quill editor -->
                    <div id="editor"></div>
                

                  <!-- Event status -->
                  <div>
                    <span>Estado do evento</span>
                    <v-radio-group inline v-model="productStatus" row>
                      <v-radio label="Normal" value="normal"></v-radio>
                      <v-radio label="Destaque" value="destaque"></v-radio>
                      <v-radio label="Adiado" value="adiado"></v-radio>
                      <v-radio label="Passado" value="passado"></v-radio>
                    </v-radio-group>
                  </div>

                  <!-- Entry text field -->
                  <v-text-field v-model="productEntry" type="text" placeholder="Entrada" variant="outlined" density="comfortable"></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-scale-transition>
              <div v-if="!loading" class="text-center"></div>
            </v-scale-transition>
            <v-btn @click="uploadImages">Salvar produto</v-btn>
          </v-card-actions>
        </v-card>
      </v-card-text>
    </v-card>
    <div v-if="showSuccessMessage" class="dialog">
    <v-card class="success-message py-8">
      <div align="center" justify="center">
        <v-btn color="#4CAF50" variant="outlined" icon="mdi-check"></v-btn>
      </div>
      <v-card-text align="center" justify="center">
        Produto adicionado com sucesso!
      </v-card-text>
    </v-card>
  </div>
  </v-dialog>

</template>


<script>
import Quill from 'quill/core';
import 'quill/dist/quill.snow.css';
import axios from 'axios';

// Import required Quill modules
import Toolbar from 'quill/modules/toolbar';
import Snow from 'quill/themes/snow';
import Bold from 'quill/formats/bold';
import Italic from 'quill/formats/italic';
import Underline from 'quill/formats/underline';
import List from 'quill/formats/list';
import Link from 'quill/formats/link';
import Header from 'quill/formats/header';
import image from 'quill/formats/image';

Quill.register({
  'modules/toolbar': Toolbar,
  'themes/snow': Snow,
  'formats/bold': Bold,
  'formats/header': Header,
  'formats/italic': Italic,
  'formats/underline': Underline,
  'formats/list': List,
  'formats/link': Link,
  'formats/image': image,
});

export default {
  data() {
    return {
      quill: null,
      uploadedImages: [],
      loading: false,
      showSuccessMessage: false,
      orderedImages: [],
      selectedFiles: [],
      productName: "",
      productDescription: "",     
      productTipo: [],
      productTipoOptions: ["Teatro", "Workshop", "Cinema", "Música", "Documentário", "Conversa"],
      productLocal: "",
      productGuest: "",
      productAddress: "",
      productExpirationDate: "",
      eventTime: "",
      productStatus: "",
      productEntry: "",
      dialogVisible: false,
    };
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.$nextTick(() => {
          if (!this.quill) {
            this.initializeQuill();
          }
        });
      }
    },
  },
  methods: {
    initializeQuill() {
      this.quill = new Quill('#editor', {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'link'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['image','code-block'],
          ]
        }
      });

      // Ensure Quill is fully initialized
      setTimeout(() => {
        this.quill.on('text-change', () => {
          // Optional: Handle text change events if needed
        });
      }, 100);
    },
    handleFileChange(event) {
      const files = event.target.files;

      // Ensure that selectedFiles is an array
      this.selectedFiles = this.selectedFiles || [];

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.uploadedImages.push({
            url: e.target.result,
            file: files[i],
          });
          this.orderImages(); // Update the ordered images
        };
        reader.readAsDataURL(files[i]);

        // Push each file to selectedFiles array
        this.selectedFiles.push(files[i]);
      }
    },
 
    orderImages() {
      // You can implement your custom logic for ordering images here
      // For example, you can use drag-and-drop functionality or other UI interactions
      this.orderedImages = [...this.uploadedImages];
    },
    removeImage(index) {
      this.uploadedImages.splice(index, 1);
      this.orderImages(); // Update the ordered images after removal
    },
    async uploadImages() {
      if (!this.quill) {
        console.error('Quill editor is not initialized');
        return;
      }
      if (this.selectedFiles && this.selectedFiles.length > 0) {
        console.log("Sending to database:", this.orderedImages);
        this.loading = true;
        this.uploadedImages = [];
        this.orderedImages = [];
      const delta = this.quill.getContents();
      const formData = new FormData();

      formData.append('productName', this.productName);
      formData.append('productLocal', this.productLocal);
      formData.append('productGuest', this.productGuest);
      formData.append('productAddress', this.productAddress);
      formData.append("productTipo", this.productTipo.join(', '));
      formData.append('productDescription', this.productDescription);
      formData.append('productMoreInfo', this.productMoreInfo);
      formData.append('productExpirationDate', this.productExpirationDate);
      formData.append('eventTime', this.eventTime);
      formData.append('productStatus', this.productStatus);
      formData.append('productEntry', this.productEntry);
      formData.append('delta', JSON.stringify(delta));

       // Append all files to the same "images" field
       for (let i = 0; i < this.selectedFiles.length; i++) {
        formData.append("images", this.selectedFiles[i]);
      }
      try {
        await axios.post('https://app.brendkit.com/EventosUpload', formData,

        );

        console.log('Content saved successfully');
        this.loading = false;
        this.showSuccessMessage = true;
        setTimeout(() => this.showSuccessMessage = false, 3000);
        this.resetForm();
      } catch (error) {
        console.error('Error saving content:', error);
      } finally {
        this.loading = false;
      }
      }
    },
    resetForm() {
      this.uploadedImages = [];
      this.orderedImages = [];
      this.selectedFiles = [];
      this.productName = "";
      this.productDescription = "";
      this.productLocal = "";
      this.productGuest = "";
      this.productAddress = "";
      this.productTipo = [];
      this.productExpirationDate = "";
      this.eventTime = "";
      this.productStatus = "normal";
      this.productEntry = "";
      // if (this.quill) {
      //   this.quill.setText('');
      // }
    },
  }
};
</script>



<style scoped>
#editor{
  height: 300px;
  border-radius: 0 0 8px 8px;
}
.success-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image-container {
  display: flex;
  flex-wrap: wrap;
}

.image-square {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 10px;
  overflow: hidden;
}

.image-square img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-icon {
  position: absolute;
  top: 5px;
  right: 5px;

  color: white;
  cursor: pointer;

  background-color: rgba(0, 0, 0, 0.7);
}
.input-container {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 8px;

  cursor: pointer;
}
.hide-input {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
}
.dialog {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.2);
}
</style>