<template>
 <div>
    <v-form @submit.prevent="handleAddItem">
        <v-combobox

v-model="chips"
:items="items"
label="Languages"    
chips
clearable        
@input="handleInput"
@change="handleChange"
>
<template v-slot:selection="{ item, select, selected, attrs }">
  <v-chip
    v-bind="attrs"
    :model-value="selected"
    closable
    @click="select"
    @click:close="remove(item)"
  >
    <strong>{{ item }}</strong>&nbsp;
    <span>(interest)</span>
  </v-chip>
</template>
</v-combobox>
    </v-form>
         <!-- Combobox with Dynamic Items -->
      
 </div>

  
 

  </template>
  
  <script>
import { ref, onMounted } from 'vue';
import axios from 'axios';

export default {
  setup() {
    const chips = ref([]);
    const items = ref([]);   
    const inputValue = ref('');
    const newItem = ref(''); 

    // Fetch items from the server
    const fetchItems = async () => {
      try {
        const response = await axios.get('http://localhost:3000/api/items');
        items.value = response.data.map(item => item.name);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    onMounted(() => {
      fetchItems();
    });

    const remove = (item) => {
      const index = chips.value.indexOf(item);
      if (index !== -1) {
        chips.value.splice(index, 1);
      }
    };

    const addNewChip = async (value) => {
      if (typeof value === 'string' && !items.value.includes(value) && value.trim() !== '') {
        try {
          await axios.post('http://localhost:3000/api/items', { name: value });
          items.value.push(value);
          newItem.value = ''; // Clear the input field after adding
        } catch (error) {
          console.error('Error adding item:', error);
        }
      }
    };

    const handleAddItem = async () => {
      if (newItem.value.trim() !== '') {
        await addNewChip(newItem.value);
      }
    };

    const handleInput = async (value) => {
      // Ensure the value is a string
      const strValue = typeof value === 'string' ? value : '';

      if (!items.value.includes(strValue) && strValue.trim() !== '') {
        inputValue.value = strValue;
        await addNewChip(strValue);
      }
    };

 
    return {
      chips,
      items,   
      newItem,
      inputValue,
      remove,
      handleAddItem,
      handleInput,
   
    };
  }
};
</script>

  