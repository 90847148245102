<template>
    <div id="app">
      <WebsiteGroup />
    </div>
  </template>
  
  <script>
  import WebsiteGroup from "../components/WebsiteGroup";
  export default {
    components: {
        WebsiteGroup,
    },
    data() {
      return {
       
      };
    },
  };
  </script>
  <style scoped></style>