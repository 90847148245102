// src/router/index.js
import { createRouter, createWebHistory } from "vue-router";
import ProductUpdate from "@/views/ProductUpdate.vue";
import ProductReservation from "@/views/ProductReservation.vue";
import PageNewsletter from "@/views/PageNewsletter.vue";
import PageForms from "@/views/PageForms.vue";
import LoginPage from "@/views/LoginPage.vue";
import RegisterUser from "@/views/RegisterUser.vue";
import RentAcar from "@/views/RentAcar.vue";
import CateringPage from "@/views/CateringPage.vue";
import CursoPage from "@/views/CursoPage.vue";
import CourseraPage from "@/views/CourseraPage.vue";
import StandardWebsites from "@/views/StandardWebsites.vue";
import MerceariaPage from "@/views/MerceariaPage.vue";
import UtopiaUpdate from "@/views/UtopiaUpdate.vue";
import EventosPage from "@/views/EventosPage.vue";
import QuillEditor from "@/components/QuillEditor.vue";
import BlogPost from "@/components/BlogPost.vue";
import PostedBlogs from "@/components/PostedBlogs.vue";
import FurniturePost from "@/components/FurniturePost.vue";
import SettingPage from "@/views/SettingPage.vue";
import SubmissionPage from "@/views/SubmissionPage.vue";
import MediaList from "@/views/MediaList.vue";
import EventosMatapa from "@/views/EventosMatapa.vue";
import WebsiteEdit from "@/views/WebsiteEdit.vue";
import WebsiteGroup from "@/views/WebsiteGroup.vue";
import ReserveCar from "@/views/ReserveCar.vue";
import CourseCard from "@/views/CourseCard.vue";
import CourseDetails from "@/views/CourseDetails.vue";
import FestivalPage from "@/views/FestivalPage.vue";




const routes = [
  {
    path: "/product-update",
    component: ProductUpdate,
    meta: { requiresAuth: true },
  },
  {
    path: "/media",
    component: MediaList,
    meta: { requiresAuth: true },
  },
  {
    path: "/mobilias",
    component: FurniturePost,
    meta: { requiresAuth: true },
  },
  {
    path: "/Posted-Blogs",
    component: PostedBlogs,
    meta: { requiresAuth: true },
  },
  {
    path: "/Blog-Post",
    component: BlogPost,
    meta: { requiresAuth: true },
  },
  {
    path: "/product-quill",
    component: QuillEditor,
    meta: { requiresAuth: true },
  },
  {
    path: "/eventos",
    component: EventosPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/festival",
    component: FestivalPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/product-reservation",
    component: ProductReservation,
    meta: { requiresAuth: true },
  },
  {
    path: "/cosmeticos",
    component: UtopiaUpdate,
    meta: { requiresAuth: true },
  },
  {
    path: "/page-newsletter",
    component: PageNewsletter,
    meta: { requiresAuth: true },
  },
  { path: "/page-forms", component: PageForms, meta: { requiresAuth: true } },
  { path: "/rent-a-car", component: RentAcar }, // Adicione a rota RentACar
  { path: "/catering", component: CateringPage },
  { path: "/curso", component:CursoPage },
  { path: "/standard-websites", component: StandardWebsites },
  { path: "/mercearia-page", component: MerceariaPage },
  { path: "/", component: LoginPage },
  { path: "/login", component: LoginPage },
  { path: "/register", component: RegisterUser },
  { path: "/setting", component:  SettingPage},
  { path: "/dashboard", component:  SubmissionPage},
  { path: "/matapa", component:  EventosMatapa},
  { path: "/websiteedit", component:  WebsiteEdit},
  { path: "/Websites", component:  WebsiteGroup},
  { path: "/reservas", component:  ReserveCar},
  { path: "/cursos", component:  CourseraPage},
  { path: "/cards", component: CourseCard },
  { path: "/details/:id", name: "CourseDetails", component: CourseDetails, props: true },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("token");
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    next("/login");
  } else {
    next();
  }
});

export default router;