<template>
  <v-row>
    <v-col cols="12">
      <v-select
        v-model="localProductCategory"
        :items="brands.map((brand) => brand.name)"
        :item-text="(brand) => brand"
        :item-value="(brand) => brands.find((b) => b.name === brand)?.name"
        label="Select Brand"
        @change="fetchSubbrands"
        outlined
      ></v-select>
    </v-col>

    <!-- Campo para Submarca (Modelo) -->
    <v-col cols="12" v-if="localProductCategory">
      <v-select
        v-model="localProductModal"
        :items="flattenedSubbrand?.map((subcat) => subcat.name) || []"
        :item-text="(subcat) => subcat.name"
        :item-value="(subcat) => subcat.id"
        :menu-props="{ group: 'brand' }"
        label="Modelo"
        dense
        @change="fetchSeats"
      ></v-select>
    </v-col>

    <!-- Campo para nome e número de assentos -->
    <v-col cols="12" v-if="localProductModal">
      <v-text-field readonly v-model="localSeats" label="Seats" outlined></v-text-field>
    </v-col>
  </v-row>
</template>

<script setup>
import { ref, watch, defineEmits, defineProps } from "vue";
import axios from "axios";

// Emit events to update parent component
const emit = defineEmits(["update:modelValue"]);

// Props to accept v-model binding from parent
const props = defineProps({
  modelValue: Object,
});

const localProductCategory = ref(props.modelValue?.productcategory || "");
const localProductModal = ref(props.modelValue?.productModal || "");
const localSeats = ref(props.modelValue?.seats || "");

// Watch to emit updates to parent component
watch(
  [localProductCategory, localProductModal, localSeats],
  ([newCategory, newModal, newSeats]) => {
    emit("update:modelValue", {
      productcategory: newCategory,
      productModal: newModal,
      seats: newSeats,
    });
  },
  { deep: true }
);

const brands = ref([]);
const flattenedSubbrand = ref([]);

// Fetch brands
const fetchBrands = async () => {
  try {
    const response = await axios.get("https://server.furnimoz.rent/api/brands/all");
    brands.value = response.data.brands || []; // Ensure brands is an array
  } catch (error) {
    console.error("Error fetching brands:", error);
  }
};

// Fetch subbrands
const fetchSubbrands = async () => {
  try {
    if (!localProductCategory.value) return;

    const response = await axios.get("https://server.furnimoz.rent/api/brands/all");
    const brands = response.data.brands;

    const productCategoryData = brands.find(
      (brand) => brand.name === localProductCategory.value
    );

    if (productCategoryData) {
      flattenedSubbrand.value = productCategoryData.subbrands || [];
    } else {
      flattenedSubbrand.value = [];
    }
  } catch (error) {
    console.error("Error fetching and filtering subbrands:", error);
  }
};

watch(localProductCategory, fetchSubbrands);

// Watch for changes in selected subbrand and update seats
watch(localProductModal, (newValue) => {
  const selectedID = String(newValue);
  const productModalData = flattenedSubbrand.value.find(
    (sub) => String(sub.name) === selectedID
  );

  if (productModalData) {
    localSeats.value = productModalData.seats || "";
  } else {
    localSeats.value = "";
  }
});

fetchBrands();
</script>
