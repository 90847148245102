<template>
  <v-sheet class="mx-auto">
    <v-slide-group v-model="model" class="pa-4" selected-class="bg-primary">
      <v-slide-group-item
        v-for="n in 4"
        :key="n"
        v-slot="{ toggle, selectedClass }"
      >
        <v-card
          color=""
          :class="['ma-4', selectedClass]"
          height="150"
          variant="outlined"
          width="333.33"
          @click="toggle"
        >
          <v-card-title>{{ Title[n - 1] }}</v-card-title>
          <v-card-text>
            <p>{{ p[n - 1] }}</p>
          </v-card-text>
        </v-card>
      </v-slide-group-item>
    </v-slide-group>

    <v-expand-transition>
      <v-sheet v-if="model != null" height="200">
        <v-card flat>
          <v-card-title class="d-flex align-center pe-2">
            <h4>Tabela de clientes</h4>
            <v-spacer></v-spacer>

            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              density="compact"
              label="Search"
              single-line
              flat
              hide-details
              variant="solo-filled"
            ></v-text-field>
          </v-card-title>

          <v-divider></v-divider>

          <v-data-table v-model:search="search" :items="formsViewItems">
            <template v-slot:[`header.stock`]>
              <div class="text-end">Stock</div>
            </template>

            <template v-slot:[`item.image`]="{ item }">
              <v-card class="my-2" elevation="2" rounded>
                <v-img
                  :src="`https://cdn.vuetifyjs.com/docs/images/graphics/gpus/${item.image}`"
                  height="64"
                  cover
                ></v-img>
              </v-card>
            </template>

            <template v-slot:[`item.rating`]="{ item }">
              <v-rating
                :model-value="item.rating"
                color="orange-darken-2"
                density="compact"
                size="small"
                readonly
              ></v-rating>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <div class="text-end">
                <v-chip
                  :color="item.status ? 'green' : 'yellow'"
                  :text="item.status ? 'Urgente' : 'Normal'"
                  class="text-uppercase"
                  label
                  size="small"
                ></v-chip>
              </div>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <div class="text-center">
                <v-btn
                  variant="text"
                  icon
                  size="small"
                  @click="deleteItem(item)"
                >
                  <v-icon> mdi-eye</v-icon>
                </v-btn>
                <v-btn
                  variant="text"
                  icon
                  size="small"
                  @click="deleteItem(item)"
                >
                  <v-icon> mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-sheet>
    </v-expand-transition>
  </v-sheet>
</template>

<script>
export default {
  data: () => ({
    model: null,
    Title: ["Compor", "Enviados", "Agendados"],
    p: [
      "This form was created to help clients manage their resevations in from their websites and build a contact list of potential leads",
      "This form was created to help clients manage their resevations in from their websites and build a contact list of potential leads",
      "This form was created to help clients manage their resevations in from their websites and build a contact list of potential leads",
    ],
    search: "",
    formsViewItems: [],
  }),
  watch: {
    model: "loadFormsViewItems",
  },
  methods: {
    loadFormsViewItems() {
      switch (this.model) {
        case 0:
          this.formsViewItems = [
            {
              Cliente: "Eugenio Mabjaia",
              Contact: "+258 84 44 54 558",
              Email: "eugeniomabjaia@gmail.com",
              nascimento: "02-04-94",
              Localizacao: "Maputo",
              request: "24-02-2024",
              status: true,
              Carro: "Rav 4",
              Modelo: "Executivo",
              image: "item1.jpg",
              action: "",
            },
            // Add more items for model 1
          ];
          break;
        case 1:
          this.formsViewItems = [
            {
              Cliente: "Eugenio Mabjaia",
              Contact: "+258 84 44 54 558",
              Email: "eugeniomabjaia@gmail.com",
              Localizacao: "Maputo",
              request: "24-02-2024",
              status: true,
              image: "1.jpg",
              action: "",
            },
            // Add more items for model 2
          ];
          break;
        case 2:
          this.formsViewItems = [
            {
              Cliente: "Eugenio Mabjaia",
              Contact: "+258 84 44 54 558",
              Email: "eugeniomabjaia@gmail.com",
              Localizacao: "Maputo",
              request: "24-02-2024",
              status: true,
              image: "1.jpg",
              action: "",
            },
            // Add more items for model 3
          ];
          break;
        case 3:
          this.formsViewItems = [
            {
              name: "Graphics Card 4A",
              image: "item4.jpg",
              rating: 4,
              stock: false,
            },
            // Add more items for model 4
          ];
          break;
        case 4:
          this.formsViewItems = [
            {
              name: "Graphics Card 5A",
              image: "item4.jpg",
              rating: 4,
              stock: false,
            },
            // Add more items for model 4
          ];
          break;
        default:
          this.formsViewItems = [];
      }
    },
  },
};
</script>
